import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { connect } from "react-redux";
import "./SideDrawer.css";
import Spinner from "react-bootstrap/Spinner";
import DatePicker from "react-datepicker";
import DayTableTimeSheet from "../TableSlider/TableSlider";
import "react-datepicker/dist/react-datepicker.css";
import NotesTimeline from "../NotesTimeline/NotesTimeline";
import { Typography } from "@material-ui/core";
import NoteIcon from "@material-ui/icons/Note";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import RenderInWindow from "../RenderInNewWindow/RenderInNewWindow";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { ax } from "utils";

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "99%",
    height: "300px",
    backgroundColor: "var(--trade-body)",
    marginTop: 10,
  },
  nextButton: {
    backgroundColor: "var(--trade-body)",
    padding: "0.7rem",
    color: "white",
    margin: "1rem auto",
    textAlign: "center",
  },
}));

var moment = require("moment"); // require

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const types = {
  FILES: "files",
  NOTES: "notes",
  PHOTOS: "photos",
  GPS_ENTRIES: "gps-entries",
  TIMELOGS: "logs",
  SAFETY_MEETINGS: "safety-meetings",
  WORKER_NOTES: "worker-notes",
  WORKER_GPS: "gps-entries-worker",
  WORKER_LOGS: "worker-logs",
  WORKER_MEETINGS: "safety-meetings-worker",
};
function SideDrawer(props) {
  const classes = useStyles();
  const [right, setRight] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);

  // objects
  const [totalItems, setTotalItems] = useState([]);
  const [dayItems, setDayItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [next, setNext] = useState(null);
  const [noteImagesNext, setNoteImagesNext] = useState(null);
  const [isExportingLogs, setIsExportingLogs] = useState(false);

  const [open, setOpen] = useState();
  const [timesheetData, setTimesheetData] = useState();

  const [startDate, endDate] = dateRange;
  const { item, type, project } = props;
  //const [itemsInDateRange, setItemsInDateRange] = useState([]);
  //const [itemsByDay, setItemsByDay] = useState([]);

  useEffect(() => {
    //fetchData(monthParam, year);
    organizeData();
  }, [totalItems, dateRange]);

  /**
   * onopen:
   *  fetchdata
   *  update totalItems
   *  useEffect will run when totalItems is updated or when date is updated to create items by day
   *    update state (create rerender)
   */

  const fetchData = (month, year) => {
    var endpoint = "";
    if (type === types.FILES) {
      endpoint =
        "/api/project-files/?project_id=" +
        project.id +
        "&file_type=document&month=" +
        month +
        "&year=" +
        year;
      ax.get(endpoint)
        .then((res) => {
          setTotalItems(res.data.results);
          setNext(res.data.next);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else if (type === types.NOTES) {
      endpoint =
        "/api/notes/?project_id=" +
        project.id +
        "&month=" +
        month +
        "&year=" +
        year;
      ax.get(endpoint)
        .then((res) => {
          setTotalItems(res.data.results);

          // itemsInRange = sortAccordingToDate(type, res.data.results);
          // tempDayItems = sortByDay(type, itemsInRange);

          // setDayItems(tempDayItems);
          setNext(res.data.next);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else if (type === types.PHOTOS) {
      fetchImages(month, year).then((res) => {
        setTotalItems(res.items);
        setNext(res.imagesNext);
        setNoteImagesNext(res.noteImagesNext);
        setLoading(false);
      });
    } else if (type === types.GPS_ENTRIES) {
      endpoint =
        "/api/gps-entries/?project_id=" +
        project.id +
        "&month=" +
        month +
        "&year=" +
        year;
      ax.get(endpoint)
        .then((res) => {
          setTotalItems(res.data.results);
          setNext(res.data.next);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else if (type === types.TIMELOGS) {
      endpoint =
        "/api/time-logs/?project_id=" +
        project.id +
        "&month=" +
        month +
        "&year=" +
        year;
      ax.get(endpoint)
        .then((res) => {
          let items = res.data.results.map((log) => {
            log.start = moment(log.start);
            log.end = moment(log.end);
            return log;
          });

          setTotalItems(items);
          setNext(res.data.next);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else if (type === types.SAFETY_MEETINGS) {
      endpoint =
        "/api/safety-meetings/?project_id=" +
        project.id +
        "&month=" +
        month +
        "&year=" +
        year;
      ax.get(endpoint)
        .then((res) => {
          setTotalItems(res.data.results);
          setNext(res.data.next);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else if (type === types.WORKER_NOTES) {
      endpoint =
        "/api/notes/?worker_id=" +
        props.worker_id +
        "&month=" +
        month +
        "&year=" +
        year;
      ax.get(endpoint)
        .then((res) => {
          setTotalItems(res.data.results);
          setNext(res.data.next);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else if (type === types.WORKER_GPS) {
      endpoint =
        "/api/gps-entries/?worker_id=" +
        props.worker_id +
        "&month=" +
        month +
        "&year=" +
        year;
      ax.get(endpoint)
        .then((res) => {
          setTotalItems(res.data.results);
          setNext(res.data.next);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else if (type === types.WORKER_LOGS) {
      endpoint =
        "/api/time-logs/?worker_id=" +
        props.worker_id +
        "&month=" +
        month +
        "&year=" +
        year;
      ax.get(endpoint)
        .then((res) => {
          let items = res.data.results.map((log) => {
            log.start = moment(log.start);
            log.end = moment(log.end);
            return log;
          });
          setTotalItems(items);
          setNext(res.data.next);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else if (type === types.WORKER_MEETINGS) {
      endpoint =
        "/api/safety-meetings/?worker_id=" +
        props.worker_id +
        "&month=" +
        month +
        "&year=" +
        year;
      ax.get(endpoint)
        .then((res) => {
          setTotalItems(res.data.results);
          setNext(res.data.next);
          console.log(res.data.next);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      console.log("wrong type");
    }
  };

  const organizeData = () => {
    var itemsInRange = [];
    var tempDayItems = [];
    itemsInRange = sortAccordingToDate(type, totalItems);
    tempDayItems = sortByDay(type, itemsInRange);
    setDayItems(tempDayItems);
  };

  const fetchImages = async (month, year) => {
    const imagesEndpoint =
      "/api/project-files/?project_id=" +
      project.id +
      "&file_type=image&month=" +
      month +
      "&year=" +
      year;
    const noteImagesEndpoint =
      "/api/notes-images/?project_id=" +
      project.id +
      "&month=" +
      month +
      "&year=" +
      year;

    const imagesResp = await ax.get(imagesEndpoint);
    const noteImagesresp = await ax.get(noteImagesEndpoint);

    const noteImagesTemp = noteImagesresp.data.results.map((elem, index) => ({
      id: elem.id,
      date_created: elem.date_created,
      file: elem.image,
      name: "note image " + index,
    }));

    let items = [...imagesResp.data.results, ...noteImagesTemp];
    items.sort(function (a, b) {
      return b.date_created.localeCompare(a.date_created);
    });

    const response = {
      items: items,
      imagesNext: imagesResp.data.next,
      noteImagesNext: noteImagesresp.data.next,
    };

    return response;
  };

  const fetchMoreData = async () => {
    if (type === types.TIMELOGS || type === types.WORKER_LOGS) {
      ax.get(next)
        .then((res) => {
          let items = res.data.results.map((log) => {
            log.start = moment(log.start);
            log.end = moment(log.end);
            return log;
          });

          setTotalItems([...totalItems, ...items]);
          setNext(res.data.next);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (type === types.PHOTOS) {
      let imagesResp;
      let noteImagesresp;
      let noteImagestemp = [];
      let imagesTemp = [];
      if (next) {
        imagesResp = await ax.get(next);
        imagesTemp = imagesResp.data.results;

        setNext(imagesResp.data.next);
      }
      if (noteImagesNext) {
        noteImagesresp = await ax.get(noteImagesNext);

        noteImagestemp = noteImagesresp.data.results.map((elem, index) => ({
          id: elem.id,
          date_created: elem.date_created,
          file: elem.image,
          name: "note image " + index,
        }));

        setNoteImagesNext(noteImagesresp.data.next);
      }

      let items = [...imagesTemp, ...noteImagestemp];
      items.sort(function (a, b) {
        return b.date_created.localeCompare(a.date_created);
      });
      setTotalItems([...totalItems, ...items]);
    } else {
      ax.get(next)
        .then((res) => {
          console.log(res.data.results);
          setTotalItems([...totalItems, ...res.data.results]);
          setNext(res.data.next);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const exportFile = () => {
    const year = props.item.split(" ")[1];
    const month = props.item.split(" ")[0];
    const monthIndex = months.indexOf(month);
    const monthParam = monthIndex + 1;

    if (type === types.WORKER_LOGS) {
      window.open(
        process.env.API_URL +
          "/api/time-logs/export_csv/?month=" +
          monthParam +
          "&year=" +
          year +
          "&worker_id=" +
          props.worker_id +
          "&token=" +
          props.token,
        "_blank"
      );
    } else if (type === types.GPS_ENTRIES) {
      window.open(
        process.env.API_URL +
          "/api/gps-entries/export_csv/?month=" +
          monthParam +
          "&year=" +
          year +
          "&token=" +
          props.token,
        "_blank"
      );
    } else if (type === types.WORKER_GPS) {
      window.open(
        process.env.API_URL +
          "/api/gps-entries/export_csv/?month=" +
          monthParam +
          "&year=" +
          year +
          "&token=" +
          props.token +
          "&worker_id=" +
          props.worker_id,
        "_blank"
      );
    } else {
      window.open(
        process.env.API_URL +
          "/api/time-logs/export_csv/?month=" +
          monthParam +
          "&year=" +
          year +
          "&token=" +
          props.token,
        "_blank"
      );
    }
  };

  const printFile = () => {
    const year = props.item.split(" ")[1];
    const month = props.item.split(" ")[0];
    const monthIndex = months.indexOf(month);
    const monthParam = monthIndex + 1;

    if (type === types.WORKER_LOGS) {
      ax.get(
        process.env.API_URL +
          "/api/time-logs/print_time_logs/?month=" +
          monthParam +
          "&year=" +
          year +
          "&worker_id=" +
          props.worker_id
      ).then((res) => {
        setTimesheetData(res.data);
        setOpen(true);
      });
    } else if (type === types.GPS_ENTRIES) {
      ax.get(
        process.env.API_URL +
          "/api/gps-entries/print_gps_entries/?month=" +
          monthParam +
          "&year=" +
          year
      ).then((res) => {
        setTimesheetData(res.data);
        setOpen(true);
      });
    } else if (type === types.WORKER_GPS) {
      ax.get(
        process.env.API_URL +
          "/api/gps-entries/print_gps_entries/?month=" +
          monthParam +
          "&year=" +
          year +
          "&worker_id=" +
          props.worker_id
      ).then((res) => {
        setTimesheetData(res.data);
        setOpen(true);
      });
    } else {
      ax.get(
        process.env.API_URL +
          "/api/time-logs/print_time_logs/?month=" +
          monthParam +
          "&year=" +
          year
      ).then((res) => {
        setTimesheetData(res.data);
        setOpen(true);
      });
    }
  };

  const childComponent = () => {
    if (props.origin === undefined) {
      return (
        <List>
          <ListItem
            style={{
              padding: 0,
              color: "var(--trade-primary)",
              cursor: "pointer",
            }}
            onClick={toggleDrawer(true, "open", props.item)}
          >
            <ListItemText>{props.item}</ListItemText>
          </ListItem>
        </List>
      );
    } else if (props.origin === "project-library-notes") {
      return (
        <WhiteTextTypography onClick={toggleDrawer(true, "open", props.item)}>
          {" "}
          <NoteIcon />{" "}
        </WhiteTextTypography>
      );
    } else if (props.origin === "project-library-files") {
      return (
        <WhiteTextTypography onClick={toggleDrawer(true, "open", props.item)}>
          {" "}
          <FileCopyIcon />{" "}
        </WhiteTextTypography>
      );
    }
  };

  const datePick = (type) => {
    return (
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        style={{
          backgroundColor: "var(--trade-background)",
          color: "var(--trade-font)",
        }}
        endDate={endDate}
        onChange={(update) => {
          let formattedStartDate = moment(update[0]).format("YYYY-MM-DD HH:MM");
          let formattedEndDate = moment(update[1]).format("YYYY-MM-DD HH:MM");
          setDateRange(update);
          switch (type) {
            case "worker-logs":
              if (update[1]) {
                ax.get(
                  `api/time-logs/?worker_id=${props.worker_id}&end__lte=${formattedEndDate}&start=${formattedStartDate}&company=${props.user.company}`
                ).then((res) => {
                  let items = res.data.results.map((log) => {
                    log.start = moment(log.start);
                    log.end = moment(log.end);
                    return log;
                  });
                  setTotalItems(items);
                });
              }
              break;
            case "gps-entries-worker":
              if (update[1]) {
                ax.get(
                  `/api/gps-entries/?worker_id=${props.worker_id}&end__lte=${formattedEndDate}&start=${formattedStartDate}&company=${props.user.company}`
                ).then((res) => {
                  let items = res.data.results.map((log) => {
                    log.start = moment(log.start);
                    log.end = moment(log.end);
                    return log;
                  });
                  setTotalItems(items);
                });
              }
              break;
            case "safety-meetings-worker":
              if (update[1]) {
                ax.get(
                  `/api/safety-meetings/?worker_id=${props.worker_id}&end=${formattedEndDate}&start=${formattedStartDate}&company=${props.user.company}`
                ).then((res) => {
                  let items = res.data.results.map((log) => {
                    log.start = moment(log.start);
                    log.end = moment(log.end);
                    return log;
                  });
                  setTotalItems(items);
                });
              }
              break;
          }
        }}
        isClearable={true}
      />
    );
  };

  const toggleDrawer = (open, state, item) => () => {
    switch (state) {
      case "open":
        setRight(open);

        // change is here  --------------------------------
        setDateRange([
          moment(props.item, "MMMM YYYY").startOf("month").toDate(),
          moment(props.item, "MMMM YYYY").endOf("month").toDate(),
        ]);
        // change is here ---------------------------------

        // const [month, year] = props.item.split(" ");
        const month = props.item.split(" ")[0];
        const year = props.item.split(" ")[1];
        const monthIndex = months.indexOf(month);
        const monthParam = monthIndex + 1;
        fetchData(monthParam, year);
        break;

      case "close":
        setRight(open);
        break;

      default:
        break;
    }
  };

  // All elems in range
  var dateAccordingToRange = [];
  const sortAccordingToDate = (choice, objects) => {
    dateAccordingToRange = [];
    var givenStartDate;
    var givenEndDate;
    var sDate, i;
    switch (choice) {
      case "files":
        if (objects.length > 0) {
          const filteredFiles = objects.filter(
            (elem) => elem.file_type === "document"
          );

          givenStartDate = moment(startDate);
          givenEndDate = moment(endDate);
          givenEndDate.set({ hour: 23, minute: 59 });

          for (i = 0; i < filteredFiles.length; i++) {
            sDate = moment(filteredFiles[i].date_created);

            if (sDate.isBetween(givenStartDate, givenEndDate)) {
              console.log("is betwween");
              dateAccordingToRange.push(filteredFiles[i]);
            }
          }
          return dateAccordingToRange;
        }
        return [];

      case "photos":
        if (objects) {
          givenStartDate = moment(startDate);
          givenEndDate = moment(endDate);
          givenEndDate.set({ hour: 23, minute: 59 });

          for (i = 0; i < objects.length; i++) {
            sDate = moment(objects[i].date_created);

            if (sDate.isBetween(givenStartDate, givenEndDate)) {
              dateAccordingToRange.push(objects[i]);
            }
          }

          return dateAccordingToRange;
        }
        return [];

      case "notes":
        if (objects.length > 0) {
          givenStartDate = moment(startDate);
          givenEndDate = moment(endDate);
          givenEndDate.set({ hour: 23, minute: 59 });

          for (i = 0; i < objects.length; i++) {
            sDate = moment(objects[i].date);

            if (sDate.isBetween(givenStartDate, givenEndDate)) {
              dateAccordingToRange.push(objects[i]);
            }
          }

          return dateAccordingToRange;
        }
        return [];

      case "gps-entries":
        if (objects.length > 0) {
          givenStartDate = moment(startDate);
          givenEndDate = moment(endDate);
          givenEndDate.set({ hour: 23, minute: 59 });

          for (i = 0; i < objects.length; i++) {
            sDate = moment(objects[i].date);

            if (sDate.isBetween(givenStartDate, givenEndDate)) {
              dateAccordingToRange.push(objects[i]);
            }
          }

          return dateAccordingToRange;
        }
        return [];

      case "logs":
        if (objects.length > 0) {
          //const filteredLogs = logs.filter((item) => item.type === "timelog");
          givenStartDate = moment(startDate);
          givenEndDate = moment(endDate);
          givenEndDate.set({ hour: 23, minute: 59 });

          for (i = 0; i < objects.length; i++) {
            sDate = moment(objects[i].start);

            if (sDate.isBetween(givenStartDate, givenEndDate)) {
              dateAccordingToRange.push(objects[i]);
            }
          }

          return dateAccordingToRange;
        }
        return [];

      case "safety-meetings":
        if (objects.length > 0) {
          givenStartDate = moment(startDate);
          givenEndDate = moment(endDate);
          givenEndDate.set({ hour: 23, minute: 59 });

          for (i = 0; i < objects.length; i++) {
            sDate = moment(objects[i].read_on);

            if (sDate.isBetween(givenStartDate, givenEndDate)) {
              dateAccordingToRange.push(objects[i]);
            }
          }

          return dateAccordingToRange;
        }
        return [];

      // worker section
      case "worker-notes":
        if (objects.length > 0) {
          givenStartDate = moment(startDate);
          givenEndDate = moment(endDate);
          givenEndDate.set({ hour: 23, minute: 59 });

          for (i = 0; i < objects.length; i++) {
            sDate = moment(objects[i].date);

            if (sDate.isBetween(givenStartDate, givenEndDate)) {
              dateAccordingToRange.push(objects[i]);
            }
          }

          return dateAccordingToRange;
        }
        return [];

      case "gps-entries-worker":
        if (objects.length > 0) {
          givenStartDate = moment(startDate);
          givenEndDate = moment(endDate);
          givenEndDate.set({ hour: 23, minute: 59 });

          for (i = 0; i < objects.length; i++) {
            sDate = moment(objects[i].date);

            if (sDate.isBetween(givenStartDate, givenEndDate)) {
              dateAccordingToRange.push(objects[i]);
            }
          }

          return dateAccordingToRange;
        }
        return [];

      case "worker-logs":
        if (objects.length > 0) {
          var givenStartDate = moment(startDate);
          var givenEndDate = moment(endDate);
          givenEndDate.set({ hour: 23, minute: 59 });

          for (i = 0; i < objects.length; i++) {
            sDate = moment(objects[i].start);

            if (sDate.isBetween(givenStartDate, givenEndDate)) {
              dateAccordingToRange.push(objects[i]);
            }
          }
          return dateAccordingToRange;
        }
        return [];

      case "safety-meetings-worker":
        if (objects.length) {
          givenStartDate = moment(startDate);
          givenEndDate = moment(endDate);
          givenEndDate.set({ hour: 23, minute: 59 });

          for (i = 0; i < objects.length; i++) {
            sDate = moment(objects[i].read_on);

            if (sDate.isBetween(givenStartDate, givenEndDate)) {
              dateAccordingToRange.push(objects[i]);
            }
          }
          return dateAccordingToRange;
        }
        return [];

      default:
        return [];
    }
  };

  const sortByDay = (choice, objectsInRange) => {
    var dict = {};
    var day, value, i;
    var result = [];

    switch (choice) {
      case "files":
        if (objectsInRange.length > 0) {
          for (i = 0; i < objectsInRange.length; i++) {
            day = moment(objectsInRange[i].date_created).format("Do MMMM YYYY");
            if (day in dict) {
              value = dict[day];
              value.push(objectsInRange[i]);
            } else {
              dict[day] = [objectsInRange[i]];
            }
          }

          if (Object.keys(dict).length !== 0) {
            for (const p in dict) {
              const values = dict[p];
              result.push(values);
            }
          }
          return result;
        }
        return [];

      case "photos":
        if (objectsInRange.length !== 0) {
          for (i = 0; i < objectsInRange.length; i++) {
            day = moment(objectsInRange[i].date_created).format("Do MMMM YYYY");
            if (day in dict) {
              value = dict[day];
              value.push(objectsInRange[i]);
            } else {
              dict[day] = [objectsInRange[i]];
            }
          }

          if (Object.keys(dict).length !== 0) {
            for (const p in dict) {
              const values = dict[p];
              result.push(values);
            }
          }

          return result;
        }
        return [];

      case "notes":
        if (objectsInRange.length > 0) {
          for (i = 0; i < objectsInRange.length; i++) {
            day = moment(objectsInRange[i].date).format("Do MMMM YYYY");

            if (day in dict) {
              value = dict[day];
              value.push(objectsInRange[i]);
            } else {
              dict[day] = [objectsInRange[i]];
            }
          }

          if (Object.keys(dict).length !== 0) {
            for (const p in dict) {
              const values = dict[p];
              result.push(values);
            }
          }

          return result;
        }
        return [];

      case "gps-entries":
        if (objectsInRange.length !== 0) {
          for (i = 0; i < objectsInRange.length; i++) {
            day = moment(objectsInRange[i].date).format("Do MMMM YYYY");
            if (day in dict) {
              value = dict[day];
              value.push(objectsInRange[i]);
            } else {
              dict[day] = [objectsInRange[i]];
            }
          }

          if (Object.keys(dict).length > 0) {
            for (const p in dict) {
              const values = dict[p];
              result.push(values);
            }
          }

          return result;
        }
        return [];

      case "logs":
        if (objectsInRange.length !== 0) {
          for (i = 0; i < objectsInRange.length; i++) {
            day = moment(objectsInRange[i].start).format("Do MMMM YYYY");
            if (day in dict) {
              value = dict[day];
              value.push(objectsInRange[i]);
            } else {
              dict[day] = [objectsInRange[i]];
            }
          }

          if (Object.keys(dict).length > 0) {
            for (const p in dict) {
              const values = dict[p];
              result.push(values);
            }
          }

          return result;
        }

        return [];

      case "safety-meetings":
        if (objectsInRange.length !== 0) {
          for (i = 0; i < objectsInRange.length; i++) {
            day = moment(objectsInRange[i].read_on).format("Do MMMM YYYY");
            if (day in dict) {
              value = dict[day];
              value.push(objectsInRange[i]);
            } else {
              dict[day] = [objectsInRange[i]];
            }
          }

          if (Object.keys(dict).length !== 0) {
            for (const p in dict) {
              const values = dict[p];
              result.push(values);
            }
          }

          return result;
        }

        return [];

      ////////////////// WORKER SECTION ////////////////////
      case "worker-notes":
        if (objectsInRange.length > 0) {
          for (i = 0; i < objectsInRange.length; i++) {
            var project_title = objectsInRange[i]["project"]["id"];

            if (project_title in dict) {
              value = dict[project_title];
              value.push(objectsInRange[i]);
            } else {
              dict[project_title] = [objectsInRange[i]];
            }
          }

          if (Object.keys(dict).length !== 0) {
            for (const p in dict) {
              const values = dict[p];
              result.push(values);
            }
          }

          return result;
        }
        return [];

      case "gps-entries-worker":
        if (objectsInRange.length > 0) {
          for (i = 0; i < objectsInRange.length; i++) {
            day = moment(objectsInRange[i].date).format("Do MMMM YYYY");
            if (day in dict) {
              value = dict[day];
              value.push(objectsInRange[i]);
            } else {
              dict[day] = [objectsInRange[i]];
            }
          }

          if (Object.keys(dict).length > 0) {
            for (const p in dict) {
              const values = dict[p];
              result.push(values);
            }
          }

          return result;
        }
        return [];

      case "worker-logs":
        if (objectsInRange.length > 0) {
          for (i = 0; i < objectsInRange.length; i++) {
            day = moment(objectsInRange[i].start).format("Do MMMM YYYY");
            if (day in dict) {
              value = dict[day];
              value.push(objectsInRange[i]);
            } else {
              dict[day] = [objectsInRange[i]];
            }
          }

          if (Object.keys(dict).length !== 0) {
            for (const p in dict) {
              const values = dict[p];
              result.push(values);
            }
          }

          return result;
        }

        return [];

      case "safety-meetings-worker":
        if (objectsInRange.length > 0) {
          for (i = 0; i < objectsInRange.length; i++) {
            day = moment(objectsInRange[i].read_on).format("Do MMMM YYYY");
            if (day in dict) {
              value = dict[day];
              value.push(objectsInRange[i]);
            } else {
              dict[day] = [objectsInRange[i]];
            }
          }

          if (Object.keys(dict).length !== 0) {
            for (const p in dict) {
              const values = dict[p];
              result.push(values);
            }
          }

          return result;
        }

        return [];

      default:
        return [];
    }
  };

  const open_file = (file) => {
    window.open(file.file, "_blank");
  };

  const LoadMoreButton = () => {
    return next ? (
      <button className={classes.nextButton} onClick={fetchMoreData}>
        Load More
      </button>
    ) : null;
  };

  // sort Files according to Date
  // view component, returns view component depending on the type of object
  const drawerContent = (type) => {
    if (type === "files") {
      return (
        <div
          style={{
            width: "40vw",
            backgroundColor: "var(--trade-body)",
            textAlign: "center",
          }}
          onKeyDown={toggleDrawer(false)}
        >
          <div className="datePicker"> Pick Date: {datePick(type)}</div>
          <div className="drawer-body">
            <div className="drawer-header">
              <span className="title">
                {moment(startDate)._isValid && moment(endDate)._isValid
                  ? moment(startDate).format("Do MMMM YYYY") +
                    " - " +
                    moment(endDate).format("Do MMMM YYYY")
                  : "Please choose date "}
              </span>
            </div>
          </div>
          {loading ? (
            <div className="spinnerContainer">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : dayItems.length > 0 ? (
            dayItems.map((item, key) => (
              <div key={key}>
                <div className="dayValue">
                  {moment(item[0].date_created).format("Do MMMM YYYY")}
                </div>
                <List
                  key={key}
                  style={{
                    margin: "0px 10px",
                    maxHeight: "100vh",
                    overflowY: "auto",
                  }}
                >
                  {item.map((i, k) => (
                    <ListItem key={k} style={{ cursor: "pointer" }}>
                      <ListItemText
                        style={{ textTransform: "capitalize" }}
                        onClick={() => open_file(i)}
                      >
                        {i.name}
                        {i.restricted && (
                          <p className="restricted-notice">
                            <i>Restricted File</i>
                          </p>
                        )}
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </div>
            ))
          ) : (
            <div className="warningMessage">
              <span>No {type} for the above period.</span>
            </div>
          )}
          <LoadMoreButton />
        </div>
      );
    }

    if (type === "photos") {
      return (
        <div
          style={{
            width: "40vw",
            backgroundColor: "var(--trade-body)",
            textAlign: "center",
          }}
          onKeyDown={toggleDrawer(false, "close")}
        >
          <div className="datePicker"> Pick Date: {datePick(type)}</div>

          <div className="drawer-body">
            <div className="drawer-header">
              <span className="title">
                {moment(startDate)._isValid && moment(endDate)._isValid
                  ? moment(startDate).format("Do MMMM YYYY") +
                    " - " +
                    moment(endDate).format("Do MMMM YYYY")
                  : "Please choose date "}
              </span>
            </div>
          </div>
          {loading ? (
            <div className="spinnerContainer">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : dayItems.length > 0 ? (
            dayItems.map((item, key) => (
              <div key={key}>
                <div className="dayValue">
                  {moment(item[0].date_created).format("Do MMMM YYYY")}
                </div>

                <List
                  key={key}
                  style={{
                    margin: "0px 10px",
                    maxHeight: "100vh",
                    overflowY: "auto",
                  }}
                  id="style-5"
                >
                  {item.map((i, k) => (
                    <ListItem key={k} style={{ cursor: "pointer" }}>
                      <ListItemText
                        style={{ textTransform: "capitalize" }}
                        onClick={() => open_file(i)}
                      >
                        {i.name}
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </div>
            ))
          ) : (
            <div className="warningMessage">
              <span>No {type} for the above period.</span>
            </div>
          )}
          <div></div>
          <LoadMoreButton />
        </div>
      );
    }

    if (type === "notes") {
      return (
        <div
          style={{
            width: "50vw",
            backgroundColor: "var(--trade-body)",
            textAlign: "center",
          }}
          onKeyDown={toggleDrawer(false, "close")}
        >
          <div className="datePicker"> Pick Date: {datePick(type)}</div>

          <div className="drawer-body">
            <div className="drawer-header">
              <span className="title">
                {moment(startDate)._isValid && moment(endDate)._isValid
                  ? moment(startDate).format("Do MMMM YYYY") +
                    " - " +
                    moment(endDate).format("Do MMMM YYYY")
                  : "Please choose date"}
              </span>
            </div>
          </div>

          {loading ? (
            <div className="spinnerContainer">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : dayItems.length > 0 ? (
            dayItems.map((item, key) => (
              <NotesTimeline item={item} key={key} type="notes" />
            ))
          ) : (
            <div className="warningMessage">
              <span>No Notes for the above period.</span>
            </div>
          )}
          <LoadMoreButton />
        </div>
      );
    }

    if (type === "gps-entries") {
      return (
        <div
          style={{
            width: "60vw",
            backgroundColor: "var(--trade-body)",
            textAlign: "center",
          }}
          //   onClick={toggleDrawer(false, "close")}
          onKeyDown={toggleDrawer(false, "close")}
        >
          <div className="logs-top-row">
            <div className="log-top-row-buttons">
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: "0.8rem 1.1rem" }}
                onClick={() => exportFile()}
              >
                Export as csv
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: "0.8rem 1.1rem" }}
                onClick={() => printFile()}
              >
                Print
              </Button>
              {open && (
                <RenderInWindow
                  timesheetData={timesheetData}
                  setOpen={setOpen}
                ></RenderInWindow>
              )}
            </div>
            <div className="datePicker"> Pick Date: {datePick(type)}</div>
          </div>

          <div className="drawer-body">
            <div className="drawer-header">
              <span className="title">
                {moment(startDate)._isValid && moment(endDate)._isValid
                  ? moment(startDate).format("Do MMMM YYYY") +
                    " - " +
                    moment(endDate).format("Do MMMM YYYY")
                  : "Please choose date"}
              </span>
            </div>
          </div>
          {loading ? (
            <div className="spinnerContainer">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : dayItems.length > 0 ? (
            dayItems.map((item, key) => {
              return (
                <div key={key}>
                  <div className="dayValue">
                    <h3>{moment(item[0].date).format("Do MMMM YYYY")}</h3>
                    <DayTableTimeSheet tableData={item} key={key} type={type} />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="warningMessage">
              <span>No GPS entries for the above period.</span>
            </div>
          )}
          <LoadMoreButton />
        </div>
      );
    }

    if (type === "logs") {
      return (
        <div
          style={{
            width: "60vw",
            backgroundColor: "var(--trade-body)",
            textAlign: "center",
          }}
          onKeyDown={toggleDrawer(false, "close")}
        >
          <div className="logs-top-row">
            <div className="log-top-row-buttons">
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: "0.8rem 1.1rem" }}
                onClick={() => exportFile()}
              >
                Export as csv
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: "0.8rem 1.1rem" }}
                onClick={() => printFile()}
              >
                Print
              </Button>
              {open && (
                <RenderInWindow
                  timesheetData={timesheetData}
                  setOpen={setOpen}
                ></RenderInWindow>
              )}
            </div>
            <div className="datePicker"> Pick Date: {datePick(type)}</div>
          </div>

          <div className="drawer-body">
            <div className="drawer-header">
              <span className="title">
                {moment(startDate)._isValid && moment(endDate)._isValid
                  ? moment(startDate).format("Do MMMM YYYY") +
                    " - " +
                    moment(endDate).format("Do MMMM YYYY")
                  : "Please choose date"}
              </span>
            </div>
          </div>
          {loading ? (
            <div className="spinnerContainer">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : dayItems.length > 0 ? (
            dayItems.map((item, key) => {
              return (
                <div key={key}>
                  <div className="dayValue">
                    <h3>{moment(item[0].start).format("Do MMMM YYYY")}</h3>
                  </div>
                  <DayTableTimeSheet
                    tableData={item}
                    key={key}
                    type={type}
                    item={props.item}
                    isExportingLogs={isExportingLogs}
                    setIsExportingLogs={setIsExportingLogs}
                  />
                </div>
              );
            })
          ) : (
            <div className="warningMessage">
              <span>No Logs for the above period.</span>
            </div>
          )}
          <LoadMoreButton />
        </div>
      );
    }

    if (type === "safety-meetings") {
      return (
        <div
          style={{
            width: "60vw",
            backgroundColor: "var(--trade-body)",
            textAlign: "center",
          }}
          onKeyDown={toggleDrawer(false, "close")}
        >
          <div className="datePicker"> Pick Date: {datePick(type)}</div>
          <div className="drawer-body">
            <div className="drawer-header">
              <span className="title">
                {moment(startDate)._isValid && moment(endDate)._isValid
                  ? moment(startDate).format("Do MMMM YYYY") +
                    " - " +
                    moment(endDate).format("Do MMMM YYYY")
                  : "Please choose date "}
              </span>
            </div>
          </div>
          {loading ? (
            <div className="spinnerContainer">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : dayItems.length > 0 ? (
            dayItems.map((item, key) => {
              return (
                <div key={key}>
                  <div className="dayValue">
                    <h3> {moment(item[0].read_on).format("Do MMMM YYYY")}</h3>
                    <DayTableTimeSheet tableData={item} key={key} type={type} />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="warningMessage">
              <span>No Safety Meetings Read for the above period.</span>
            </div>
          )}
          <LoadMoreButton />
        </div>
      );
    }
    ////// WORKER SECTION ////////
    if (type === "worker-notes") {
      return (
        <div
          style={{
            width: "50vw",
            backgroundColor: "var(--trade-body)",
            textAlign: "center",
          }}
          onKeyDown={toggleDrawer(false, "close")}
        >
          <div className="datePicker"> Pick Date: {datePick(type)}</div>

          <div className="drawer-body">
            <div className="drawer-header">
              <span className="title">
                {moment(startDate)._isValid && moment(endDate)._isValid
                  ? moment(startDate).format("Do MMMM YYYY") +
                    " - " +
                    moment(endDate).format("Do MMMM YYYY")
                  : "Please choose date"}
              </span>
            </div>
          </div>

          {loading ? (
            <div className="spinnerContainer">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : dayItems.length > 0 ? (
            dayItems.map((item, key) => (
              <NotesTimeline item={item} key={key} type="worker-notes" />
            ))
          ) : (
            <div className="warningMessage">
              <span>No Notes for the above period.</span>
            </div>
          )}

          <LoadMoreButton />
        </div>
      );
    }

    if (type === "gps-entries-worker") {
      return (
        <div
          style={{
            width: "60vw",
            backgroundColor: "var(--trade-body)",
            textAlign: "center",
          }}
          onKeyDown={toggleDrawer(false, "close")}
        >
          <div className="logs-top-row">
            <div className="log-top-row-buttons">
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: "0.8rem 1.1rem" }}
                onClick={() => exportFile()}
              >
                Export as csv
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: "0.8rem 1.1rem" }}
                onClick={() => printFile()}
              >
                Print
              </Button>
              {open && (
                <RenderInWindow
                  timesheetData={timesheetData}
                  setOpen={setOpen}
                ></RenderInWindow>
              )}
            </div>
            <div className="datePicker"> Pick Date: {datePick(type)}</div>
          </div>

          <div className="drawer-body">
            <div className="drawer-header">
              <span className="title">
                {moment(startDate)._isValid && moment(endDate)._isValid
                  ? moment(startDate).format("Do MMMM YYYY") +
                    " - " +
                    moment(endDate).format("Do MMMM YYYY")
                  : "Please choose date"}
              </span>
            </div>
          </div>

          {loading ? (
            <div className="spinnerContainer">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : dayItems.length > 0 ? (
            dayItems.map((item, key) => {
              return (
                <div key={key}>
                  <div className="dayValue">
                    <h3>{moment(item[0].date).format("Do MMMM YYYY")}</h3>
                    <DayTableTimeSheet
                      tableData={item}
                      key={key}
                      type={"gps-entries"}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="warningMessage">
              <span>No GPS entries for the above period.</span>
            </div>
          )}
          <LoadMoreButton />
        </div>
      );
    }

    if (type === "worker-logs") {
      return (
        <div
          style={{
            width: "65vw",
            backgroundColor: "var(--trade-body)",
            textAlign: "center",
          }}
          onKeyDown={toggleDrawer(false, "close")}
        >
          <div className="logs-top-row">
            <div className="log-top-row-buttons">
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: "0.8rem 1.1rem" }}
                onClick={() => exportFile()}
              >
                Export as csv
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: "0.8rem 1.1rem" }}
                onClick={() => printFile()}
              >
                Print
              </Button>
              {open && (
                <RenderInWindow
                  timesheetData={timesheetData}
                  setOpen={setOpen}
                ></RenderInWindow>
              )}
            </div>
            <div className="datePicker"> Pick Date: {datePick(type)}</div>
          </div>

          <div className="drawer-body">
            <div className="drawer-header">
              <span className="title">
                {moment(startDate)._isValid && moment(endDate)._isValid
                  ? moment(startDate).format("Do MMMM YYYY") +
                    " - " +
                    moment(endDate).format("Do MMMM YYYY")
                  : "Please choose date"}
              </span>
            </div>
          </div>

          {loading ? (
            <div className="spinnerContainer">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : dayItems.length !== 0 ? (
            dayItems.map((item, key) => {
              return (
                <div key={key}>
                  <div className="dayValue">
                    <h3>{moment(item[0].start).format("Do MMMM YYYY")}</h3>
                    <DayTableTimeSheet
                      tableData={item}
                      key={key}
                      type={"worker-logs"}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="warningMessage">
              <span>No Time sheet detail on worker.</span>
            </div>
          )}
          <LoadMoreButton />
        </div>
      );
    }

    if (type === "safety-meetings-worker") {
      return (
        <div
          style={{
            width: "60vw",
            backgroundColor: "var(--trade-body)",
            textAlign: "center",
          }}
          //   onClick={toggleDrawer(false, "close")}
          onKeyDown={toggleDrawer(false, "close")}
        >
          <div className="datePicker"> Pick Date: {datePick(type)}</div>

          <div className="drawer-body">
            <div className="drawer-header">
              <span className="title">
                {moment(startDate)._isValid && moment(endDate)._isValid
                  ? moment(startDate).format("Do MMMM YYYY") +
                    " - " +
                    moment(endDate).format("Do MMMM YYYY")
                  : "Please choose date "}
              </span>
            </div>
          </div>

          {loading ? (
            <div className="spinnerContainer">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : dayItems.length !== 0 ? (
            dayItems.map((item, key) => {
              return (
                <div key={key}>
                  <div className="dayValue">
                    <h3> {moment(item[0].read_on).format("Do MMMM YYYY")}</h3>
                    <DayTableTimeSheet
                      tableData={item}
                      key={key}
                      type={"safety-meetings"}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="warningMessage">
              <span>No Safety Meetings Read for the above period.</span>
            </div>
          )}
          <LoadMoreButton />
        </div>
      );
    }
  };

  return (
    <div>
      <React.Fragment>
        {childComponent()}
        <Drawer
          anchor="right"
          open={right}
          onClose={toggleDrawer(false, "close")}
        >
          <div
            style={{
              backgroundColor: "var(--trade-body)",
              color: "white",
              minHeight: "100vh",
            }}
          >
            {drawerContent(props.type)}
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    //user: state.auth.user,
    project: state.projects.item,
    token: state.auth.token,
    user: state.auth.user,
    // project items
    // notes: state.notes.current_project_items,
    // logs: state.time_logs.current_project_items,
    // files: state.files.current_project_files,
    // images: state.files.current_project_images,
    // note_images: state.files.current_project_note_images,
    // safety_meetings_project: state.safety_meetings.current_project_items,
    // gpsEntries: state.user_positions.current_project_items,
    // // URLs to fetch additional data (pages)
    // notes_next: state.notes.next,
    // time_logs_next: state.time_logs.next,
    // files_next: state.files.files_next,
    // images_next: state.files.images_next,
    // note_images_next: state.files.note_images_next,
    // safety_meetings_next: state.safety_meetings.next,
    // gps_entries_next: state.user_positions.next
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // fetch_more_notes: (url) => {
    //   return dispatch(notes.fetchMoreNotesByProjectId(url));
    // },
    // fetch_more_timelogs: (url) => {
    //   return dispatch(time_logs.fetchMoreTimelogsByProjectId(url));
    // },
    // fetch_more_files: (url) => {
    //   return dispatch(files.fetchMoreFilesByProjectId(url));
    // },
    // fetch_more_images: (url) => {
    //   return dispatch(files.fetchMoreImagesByProjectId(url));
    // },
    // fetch_more_note_images: (url) => {
    //   return dispatch(files.fetchMoreNoteImagesByProjectId(url));
    // },
    // fetch_more_safety_meetings: (url) => {
    //   return dispatch(safety_meetings.fetchMoreSafetyMeetingsByProjectId(url));
    // },
    // fetch_more_gps_entries: (url) => {
    //   return dispatch(user_positions.fetchMoreEntriesByProjectId(url));
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideDrawer);
//export default SideDrawer;
