import React, { Component, createRef } from "react";
import Button from "components/CustomButtons/Button.jsx";

import { Button as MaterialButton } from "@material-ui/core";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import Dialog from "@material-ui/core/Dialog";
import MenuItem from "@material-ui/core/MenuItem";
import { time_logs, safety_meetings, projects } from "actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import "./LoginWorker.css";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CssTextField from "components/CssTextField/CssTextField";
import { ax } from "utils";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment/moment";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class LoginWorker extends Component {
  safetyMeetingContainer = createRef();
  state = {
    open: false,
    projectID: "",
    taskID: "",
    workerID: "",
    projects: [],
    loadingProjects: true,
    availableProjects: [],
    availableTasks: [],
    availableWorkers: [],
    safetyMeetingInfos: [],
    safetyMeetings: [],
    currentSafetyMeetingInfo: null,
    currentSafetyMeeting: null,
    buttonValue: true,
    lat: null,
    long: null,
    snackValue: false,
    snackText: "",
    current_index: 0,
    items_read: 0,
    projectSelectorLocked: false,
  };

  startEnabled() {
    const { user, gpsEnabled } = this.props;
    // if (user.company_subscription_active === false) return false;
    return !(user.gps_forced && gpsEnabled === false);
  }

  handleClickOpen = () => {
    this.setState(
      {
        open: true,
      },
      () => {
        ax.get("/api/projects/login/?archived=" + false)
          .then((res) => {
            const projects = res.data.sort((a, b) =>
              a.title.localeCompare(b.title)
            );

            this.setState({
              loadingProjects: false,
              availableProjects: projects,
            });
          })
          .catch((err) => {
            this.setState({
              loadingProjects: false,
            });
            console.log(err);
          });
      }
    );
    // this.interval = setInterval(, 3000);
  };

  checkScroll = () => {
    var el = document.getElementById("style-5");

    if (el) {
      var curOverflow = el.style.overflow;

      if (!curOverflow || curOverflow === "visible")
        el.style.overflow = "hidden";

      var isOverflowing =
        el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

      el.style.overflow = curOverflow;

      console.log(isOverflowing);
      return true;
    }
  };

  handleClose = () => {
    // clearInterval(this.interval);
    this.setState({
      open: false,
      projectID: "",
      workerID: "",
      taskID: "",
      currentSafetyMeetingInfo: null,
      availableTasks: [],
      availableWorkers: [],
      availableProjects: [],
      projectSelectorLocked: false,
    });
  };

  updateValues = () => {
    this.setState({
      open: false,
      projectID: null,
      workerID: null,
      taskID: null,
    });
    this.props.redirect();
  };

  deploySafetyMeeting = () => {
    if (this.state.projectID && this.state.taskID && this.state.workerID) {
      this.getSafetyMeetings();
    }
  };

  getSafetyMeetings = () => {
    const { user } = this.props;
    const { projectID, taskID, workerID } = this.state;

    this.setState({
      loadingProjects: true,
    });

    this.props.getSafetyMeetings(taskID).then((res) => {
      if (res.items.length === 0) {
        this.setState({
          ...this.state,
          currentSafetyMeetingInfo: null,
          safetyMeeting: false,
          loadingProjects: false,
        });
      } else {
        const meetings = [];
        for (var i = 0; i < res.items.length; i++) {
          meetings.push({
            safety_meeting_info: res.items[i].id,
            user: workerID,
            created_by: user.id,
            project_id: projectID,
            fields: {},
          });

          var lastMeeting = meetings[meetings.length - 1];

          res.items[i].fields.forEach((elem) => {
            elem.field_value = "";
            lastMeeting.fields[elem.id] = elem;
          });
        }

        this.setState({
          ...this.state,
          currentSafetyMeetingInfo: res.items[0],
          currentSafetyMeeting: meetings[0],
          safetyMeetingInfos: res.items,
          safetyMeetings: meetings,
          safety_meetings_count: res.items.length,
          current_index: 0,
          items_read: 0,
          isLoading: true,
          buttonValue: false,
          loadingProjects: false,
        });
      }
    });
  };

  displayFieldForms = (ffs) => {
    return (
      <Grid container>
        <Grid item md={5} style={{ margin: "auto" }}>
          <Box
            m={1}
            style={{
              height: "5px",
              backgroundColor: "var(--trade-background)",
            }}
            justifyContent="center"
            display="flex"
            alignItems="center"
          ></Box>
        </Grid>
        <Grid item md={2}>
          <Box m={1} justifyContent="center" display="flex" alignItems="center">
            <Typography variant="h5">Q &amp; A</Typography>
          </Box>
        </Grid>
        <Grid item md={5} style={{ margin: "auto" }}>
          <Box
            m={1}
            style={{
              height: "5px",
              backgroundColor: "var(--trade-background)",
            }}
            justifyContent="center"
            display="flex"
            alignItems="center"
          ></Box>
        </Grid>
        {ffs.map((elem) => this.displayFieldForm(elem))}
      </Grid>
    );
  };

  displaySafetyMeeting = () => {
    const { currentSafetyMeetingInfo } = this.state;
    //this.checkScroll();

    const data = (
      <div
        style={{ backgroundColor: "var(--trade-background)", color: "white" }}
      >
        <Typography variant="h5" style={{ textAlign: "center" }}>
          {currentSafetyMeetingInfo.title}
        </Typography>
        <Typography variant="h6" style={{ textAlign: "center" }}>
          Safety Meeting Code: {currentSafetyMeetingInfo.code}
        </Typography>
        {currentSafetyMeetingInfo.last_update ? (
          <Typography variant="subtitle1" style={{ textAlign: "left" }}>
            Last update :{" "}
            {moment(currentSafetyMeetingInfo.last_update).format("YYYY-MM-DD")}
          </Typography>
        ) : (
          ""
        )}
        <pre
          style={{
            whiteSpace: "pre-wrap",
            color: "var(--trade-font)",
            height: "auto",
          }}
        >
          {currentSafetyMeetingInfo.description}
        </pre>
        {currentSafetyMeetingInfo.images.length ? (
          <div>
            {" "}
            <p style={{ textTransform: "uppercase" }}>
              <strong> Images</strong>
            </p>{" "}
            <div className="safetyImageWrapper">
              <div className="safetyImageConatiner">
                {currentSafetyMeetingInfo.images.map((image, index) => {
                  return (
                    <img
                      src={image.image}
                      alt={currentSafetyMeetingInfo.title}
                      key={index}
                      className="safetyImage"
                    />
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
        {this.displayFieldForms(currentSafetyMeetingInfo.fields)}
      </div>
    );

    return data;
  };

  handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      this.setState({
        buttonValue: true,
      });
    } else {
      this.setState({
        buttonValue: false,
      });
    }
  };

  static getRequiredFields(safety_meeting) {
    return safety_meeting.fields.filter((field) => field.required);
  }

  showNextMeeting = () => {
    // check that fields are filled
    // acknowledge meeting to the backend
    // if ack successful, proceed to show next meeting by updating state

    const fieldKeys = Object.keys(this.state.currentSafetyMeeting.fields);
    for (var i = 0; i < fieldKeys.length; i++) {
      if (
        this.state.currentSafetyMeeting.fields[fieldKeys[i]].field_value === ""
      ) {
        //show snackbar
        this.setState({
          snackValue: true,
          snackText: "Please make sure to answer all the questions",
        });
        return;
      }
    }

    this.props
      .ackMeeting(
        this.state.currentSafetyMeeting.safety_meeting_info,
        this.state.currentSafetyMeeting.project_id,
        this.state.currentSafetyMeeting.user,
        this.state.currentSafetyMeeting.fields,
        this.state.taskID,
        this.state.timeLogID
      )
      .then((elem) => {
        if (elem && elem.type === "ACK_SAFETY_MEETING") {
          const next_index = this.state.current_index + 1;

          this.setState({
            currentSafetyMeetingInfo: this.state.safetyMeetingInfos[next_index],
            currentSafetyMeeting: this.state.safetyMeetings[next_index],
            current_index: next_index,
            items_read: this.state.items_read + 1,
          });
          var myDiv = document.getElementById("style-5"); //Scroll to top
          myDiv.scrollTop = 0;
        } else {
          this.setState({
            snackValue: true,
            snackText: "Unable to acknowledge safety meeting at the moment",
          });
          return;
        }
      });
  };

  goToDashboard = () => {
    // check that all fields are filled
    // acknowledge meeting
    // if ack successful, start timelog for worker
    const fieldKeys = Object.keys(this.state.currentSafetyMeeting.fields);
    for (var i = 0; i < fieldKeys.length; i++) {
      if (
        this.state.currentSafetyMeeting.fields[fieldKeys[i]].field_value === ""
      ) {
        //show snackbar
        this.setState({
          snackValue: true,
          snackText: "Please make sure to answer all the questions",
        });
        return;
      }
    }

    this.props
      .ackMeeting(
        this.state.currentSafetyMeeting.safety_meeting_info,
        this.state.currentSafetyMeeting.project_id,
        this.state.currentSafetyMeeting.user,
        this.state.currentSafetyMeeting.fields,
        this.state.taskID,
        this.state.timeLogID
      )
      .then((elem) => {
        if (elem && elem.type === "ACK_SAFETY_MEETING") {
          this.updateValues();
        } else {
          this.setState({
            snackValue: true,
            snackText: "Unable to acknowledge safety meeting at the moment",
          });
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleSnackClose = () => {
    this.setState({
      snackValue: false,
      snackText: "",
    });
  };

  async startTimer() {
    const { projectID, taskID, workerID, lat, long } = this.state;
    if (projectID === "") {
      this.setState({
        snackValue: true,
        snackText: "Please select a project",
      });
      return;
    }

    if (taskID === "") {
      this.setState({
        snackValue: true,
        snackText: "Please select a task",
      });
      return;
    }

    if (workerID === "") {
      this.setState({
        snackValue: true,
        snackText: "Please select a worker to log in",
      });
      return;
    }

    let formattedLat = parseFloat(lat) ? parseFloat(lat) : null;
    let formattedLong = parseFloat(lat) ? parseFloat(lat) : null;

    return this.props.startTimer(
      projectID,
      formattedLat,
      formattedLong,
      workerID,
      taskID
    );
  }

  handleSMChange = (ev) => {
    if (
      this.safetyMeetingContainer.current.scrollHeight ===
        this.safetyMeetingContainer.current.clientHeight &&
      this.state.safetyMeeting !== null
    ) {
      this.setState({
        buttonValue: true,
      });
    }
  };

  buttonDisabled() {
    return false;
    // const { buttonValue, lat, long } = this.state;
    // if (buttonValue && long !== null && lat !== null) {
    //   // button will be enabled
    //   return false;
    // } else {
    //   return true;
    // }
  }

  handleProjectChange = (event) => {
    const projectObj = this.state.availableProjects.filter(
      (elem) => elem.id === event.target.value
    )[0];
    if (projectObj !== undefined) {
      const workers = projectObj.workers.sort((a, b) =>
        a.first_name.localeCompare(b.first_name)
      );

      this.setState({
        availableTasks: projectObj.tasks,
        availableWorkers: workers,
        taskID: "",
        workerID: "",
        currentSafetyMeetingInfo: null,
        projectID: event.target.value,
        lat: projectObj.latitude,
        long: projectObj.longitude,
      });
    }
  };

  readSafetyMeetings = () => {
    const { projectID, taskID, workerID } = this.state;
    if (projectID === "") {
      this.setState({
        snackValue: true,
        snackText: "Please select a project",
      });
      return;
    }

    if (taskID === "") {
      this.setState({
        snackValue: true,
        snackText: "Please select a task",
      });
      return;
    }

    if (workerID === "") {
      this.setState({
        snackValue: true,
        snackText: "Please select a worker to log in",
      });
      return;
    }
    // start time log right now, put everything else in read only, save the time log id
    this.setState({
      projectSelectorLocked: true,
    });
    this.startTimer().then((response) => {
      this.setState({
        timeLogID: response.item.id,
      });
      this.deploySafetyMeeting();
    });
  };

  handleTaskChange = (event) => {
    this.setState(
      {
        taskID: event.target.value,
      },
      () => {
        this.readSafetyMeetings();
      }
    );
  };

  handleWorkerChange = (event) => {
    this.setState({
      workerID: event.target.value,
    });
  };

  projectSelect = () => {
    return (
      <React.Fragment>
        {this.state.loadingProjects ? (
          <div className="spinner-container">
            <Spinner animation="border" variant="warning" className="spinner" />
          </div>
        ) : (
          <CssTextField
            value={this.state.projectID}
            onChange={this.handleProjectChange}
            variant="outlined"
            label="Select Project"
            disabled={this.state.projectSelectorLocked}
            select
            style={{
              minWidth: "15vw",
              marginRight: "2rem",
              marginBottom: "1rem",
            }}
          >
            {this.state.availableProjects
              .filter((elem) => !elem.archived)
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((project, index) => (
                <MenuItem
                  value={project.id}
                  key={index}
                  style={{ textTransform: "capitalize" }}
                >
                  {project.title}
                </MenuItem>
              ))}
          </CssTextField>
        )}
      </React.Fragment>
    );
  };

  taskSelect = () => {
    return (
      <React.Fragment>
        {this.state.loadingProjects ? (
          <div className="spinner-container">
            <Spinner animation="border" variant="warning" className="spinner" />
          </div>
        ) : this.state.availableTasks.length > 0 ? (
          <CssTextField
            value={this.state.taskID}
            onChange={this.handleTaskChange}
            variant="outlined"
            label="Select Task"
            disabled={this.state.projectSelectorLocked}
            select
            style={{
              minWidth: "15vw",
              marginRight: "2rem",
              marginBottom: "1rem",
            }}
          >
            {this.state.availableTasks
              .filter((task) => task.title !== "Site Worker")
              .map((task) => (
                <MenuItem
                  value={task.id}
                  key={task.id}
                  style={{ textTransform: "capitalize" }}
                >
                  {task.title}
                </MenuItem>
              ))}
          </CssTextField>
        ) : null}
      </React.Fragment>
    );
  };

  workerSelect = () => {
    return (
      <React.Fragment>
        {this.state.loadingProjects ? (
          <div className="spinner-container">
            <Spinner animation="border" variant="warning" className="spinner" />
          </div>
        ) : this.state.availableWorkers.length > 0 ? (
          <CssTextField
            value={this.state.workerID}
            onChange={this.handleWorkerChange}
            variant="outlined"
            label="Select Worker"
            disabled={this.state.projectSelectorLocked}
            select
            style={{
              minWidth: "15vw",
              marginRight: "2rem",
              marginBottom: "1rem",
            }}
          >
            {this.state.availableWorkers.length > 0 &&
            this.props.user.id !== this.state.availableWorkers[0].id ? (
              this.state.availableWorkers
                .filter((worker) => this.props.user.id !== worker.id)
                .map((worker) => (
                  <MenuItem
                    value={worker.id}
                    key={worker.id}
                    style={{ textTransform: "capitalize" }}
                  >
                    {worker.first_name} {worker.last_name}
                  </MenuItem>
                ))
            ) : (
              <MenuItem disabled>No workers available</MenuItem>
            )}
          </CssTextField>
        ) : null}
      </React.Fragment>
    );
  };

  handleFieldFormChange = (ev) => {
    const meeting = this.state.currentSafetyMeeting;
    meeting.fields[ev.target.name].field_value = ev.target.value;

    this.setState({
      currentSafetyMeeting: meeting,
    });
  };

  displayFieldForms = (ffs) => {
    return (
      <Grid container>
        <Grid item md={5} style={{ margin: "auto" }}>
          <Box
            m={1}
            style={{ height: "5px", backgroundColor: "#fff" }}
            justifyContent="center"
            display="flex"
            alignItems="center"
          ></Box>
        </Grid>
        <Grid item md={2}>
          <Box m={1} justifyContent="center" display="flex" alignItems="center">
            <Typography variant="h5">Q &amp; A</Typography>
          </Box>
        </Grid>
        <Grid item md={5} style={{ margin: "auto" }}>
          <Box
            m={1}
            style={{ height: "5px", backgroundColor: "#fff" }}
            justifyContent="center"
            display="flex"
            alignItems="center"
          ></Box>
        </Grid>
        {ffs.map((elem) => this.displayFieldForm(elem))}
      </Grid>
    );
  };

  displayTextField = (fieldForm) => {
    return (
      <Grid container item md={12} key={fieldForm.id}>
        <Grid item md={12}>
          <Box m={1}>{fieldForm.title}</Box>
        </Grid>

        <CssTextField
          value={
            this.state.currentSafetyMeeting.fields[fieldForm.id].field_value
          }
          name={fieldForm.id}
          onChange={this.handleFieldFormChange}
          variant="outlined"
          style={{
            minWidth: "15vw",
            marginRight: "2rem",
            marginBottom: "1rem",
          }}
        />
      </Grid>
    );
  };

  displayChoicesFieldForm = (fieldForm) => {
    const optionList = fieldForm.options
      .split("\r\n")
      .map((elem) => elem.split("|")[0]);
    return (
      <Grid container item md={12} key={fieldForm.id}>
        <Grid item md={12}>
          <Box m={1}>{fieldForm.title}</Box>
        </Grid>
        <CssTextField
          value={
            this.state.currentSafetyMeeting.fields[fieldForm.id].field_value
          }
          onChange={this.handleFieldFormChange}
          name={fieldForm.id}
          variant="outlined"
          style={{
            minWidth: "15vw",
            marginRight: "2rem",
            marginBottom: "1rem",
          }}
        >
          {optionList.map((elem) => (
            <MenuItem value={elem} key={elem}>
              {elem}
            </MenuItem>
          ))}
        </CssTextField>
      </Grid>
    );
  };

  displayFieldForm = (fieldForm) => {
    switch (fieldForm.type) {
      case "choices":
        return this.displayChoicesFieldForm(fieldForm);
      case "string":
        return this.displayTextField(fieldForm);
      default:
        return null;
    }
  };

  render() {
    const {
      open,
      currentSafetyMeetingInfo,
      projectID,
      taskID,
      workerID,
      items_read,
      safety_meetings_count,
    } = this.state;

    const itemString = "safety meeting";

    return (
      <div>
        <MaterialButton
          // color="secondary"
          style={{ fontSize: "0.8rem", color: "var(--trade-success)" }}
          onClick={this.handleClickOpen}
        >
          <AddCircleIcon style={{ fill: "var(--trade-success)" }} /> &nbsp;
          login worker
        </MaterialButton>
        <Dialog
          maxWidth="lg"
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <div className="loginWorkerWrapper" onMouseOver={this.handleSMChange}>
            <div className="LoginWorkerContainer">
              <div className="projectSelectionContainer">
                <div className="projectSelectionWrapper">
                  {this.projectSelect()}
                  {this.workerSelect()}
                  {this.taskSelect()}
                </div>
              </div>
              <div
                className="safetyMeetingContainer"
                id="style-5"
                ref={this.safetyMeetingContainer}
                onScroll={this.handleScroll}
              >
                {currentSafetyMeetingInfo ? (
                  this.displaySafetyMeeting()
                ) : projectID && taskID && workerID ? (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "var(--trade-background)",
                    }}
                  >
                    <p>No Safety Meeting Assigned.</p>
                  </div>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "var(--trade-background)",
                    }}
                  >
                    <p>Please Select Project, Worker &amp; Task.</p>
                  </div>
                )}
              </div>

              {currentSafetyMeetingInfo ? (
                items_read === safety_meetings_count - 1 ? (
                  <React.Fragment>
                    <Button
                      round
                      style={{ backgroundColor: "green", color: "white" }}
                      size="lg"
                      disabled={this.buttonDisabled()}
                      className="start_timer"
                      onClick={this.goToDashboard}
                    >
                      I have read and understood
                      <br /> the {itemString}
                    </Button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Button
                      round
                      color="success"
                      size="lg"
                      disabled={this.buttonDisabled()}
                      className="start_timer"
                      onClick={this.showNextMeeting}
                    >
                      I have read and understood
                      <br /> the {itemString}, read next item
                    </Button>
                  </React.Fragment>
                )
              ) : null}
            </div>
          </div>
          <div>
            <Snackbar
              open={this.state.snackValue}
              autoHideDuration={6000}
              onClose={this.handleSnackClose}
            >
              <Alert onClose={this.handleSnackClose} severity="error">
                {this.state.snackText}
              </Alert>
            </Snackbar>
          </div>
        </Dialog>
      </div>
    );
  }
}

LoginWorker.propTypes = {
  user: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  longitude: PropTypes.number,
  latitude: PropTypes.number,
  gpsEnabled: PropTypes.bool,
};

LoginWorker.defaultProps = {
  longitude: null,
  latitude: null,
  gpsEnabled: false,
  history: null,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
    projects: state.projects.global_items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startTimer: (project_id, latitude, longitude, worker_id, task_id) => {
      return dispatch(
        time_logs.startWorkerTimelog(
          project_id,
          latitude,
          longitude,
          worker_id,
          task_id
        )
      );
    },
    getSafetyMeetings: (task_type_id) => {
      return dispatch(
        safety_meetings.fetchSafetyMeetingsInfoForTask(task_type_id)
      );
    },
    ackMeeting: (
      meeting_id,
      project_id,
      worker_id,
      fields_values,
      task_id,
      timelog_id
    ) => {
      if (fields_values === undefined) fields_values = [];
      return dispatch(
        safety_meetings.acknowledgeMeeting(
          meeting_id,
          project_id,
          worker_id,
          fields_values,
          task_id,
          timelog_id
        )
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginWorker);
