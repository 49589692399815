import React from "react";
import { ax } from "utils";
import { connect } from "react-redux";
import { geolocated } from "react-geolocated";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import AddIcon from "@material-ui/icons/Add";
import AppBar from "@material-ui/core/AppBar";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Button from "components/CustomButtons/Button.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import CreateSafetyMeetingSlider from "../../newComponents/CreateSafetyMeetingSlider/CreateSafetyMeetingSlider";
import CssTextField from "components/CssTextField/CssTextField";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import CustomizedDialogs from "components/CustomDialog/AddWorkerDialog.jsx";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import MuiAlert from "@material-ui/lab/Alert";
import RemoveIcon from "@material-ui/icons/Remove";
import SafetyMeetingSlider from "../../newComponents/SafetyMeetingSlider/SafetyMeetingSlider";
import SearchIcon from "@material-ui/icons/Search";
import Snackbar from "@material-ui/core/Snackbar";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TaskAndMeetingTable from "../../newComponents/TaskAndMeetingTable/TaskAndMeetingTable";
import { Typography, Box } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";

import "./AddProjectPage.css";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Circle,
} from "react-google-maps";
import { projects, companies, safety_meetings, workers } from "actions";
import Check from "@material-ui/icons/Check";

const projectStyles = (theme) => ({
  ...customCheckboxRadioSwitch,
  root: {
    "& .MuiPaper-root": {
      width: "100%",
      backgroundColor: "transparent",
    },
  },
  progress: {
    marginBottom: "0px",
  },
  helperText: {
    color: "var(--trade-font)",
  },
  select: {
    "& label.Mui-focused": {
      color: "var(--trade-font)",
      borderColor: "var(--trade-neutral)",
    },
    "& .MuiInputBase-root": {
      color: "var(--trade-font)",
      borderColor: "var(--trade-neutral)",
    },
    "& .MuiFormLabel-root": {
      color: "var(--trade-font)",
      borderColor: "var(--trade-neutral)",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid var(--trade-neutral)",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px solid green",
    },
    "& .MuiSelect-icon": {
      color: "var(--trade-font)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "var(--trade-neutral)",
      },
      "&:hover fieldset": {
        borderColor: "var(--trade-neutral)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--trade-success)",
      },
    },
  },
  chip: {
    borderColor: "var(--trade-neutral)",
    color: "var(--trade-font)",
  },
  safetyMeetingSection: {
    flexGrow: 1,
    margin: "1rem",
  },
  datePicker: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  stepLabel: {
    "& .MuiStepLabel-label": {
      color: "var(--trade-font)",
      fontWeight: "bold",
    },
  },
  stepIcon: {
    "& .MuiStepIcon-root": {
      color: "#ffff",
      fill: "grey",
    },
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: "#ffff",

      fill: "var(--trade-success)",
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: "#ffff",
      fill: "var(--trade-success)",
    },
    "& .MuiStepIcon-root.MuiStepIcon": {
      color: "#ffff",
      fill: "var(--trade-success)",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    color: "var(--trade-font)",
    "& .MuiFormLabel-root": {
      color: "var(--trade-font)",
      borderColor: "var(--trade-neutral)",
    },
  },
  underline: {
    color: "var(--trade-font)",
    borderColor: "#fff",
  },
  stepSubtitle: {
    margin: "0px auto",
  },
  stepDescription: {
    textAlign: "center",
    color: "var(--trade-font)",
  },
  passwordReqs: {
    textAlign: "center",
    fontSize: "0.75rem",
  },
  planCard: {
    padding: "1rem",
  },
  inputError: {
    textAlign: "center",
    color: "var(--trade-tertiary)",
    fontSize: "0.8rem",
  },
  planCardTopBox: {
    backgroundColor: "green",
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: "bold",
  },
  cardChosen: {
    border: "5px solid green",
  },
  cardSubtitle: {
    fontWeight: "bold",
    marginBottom: "3px",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  taskContainer: {
    backgroundColor: "var(--trade-body)",
    height: "auto",
    borderRadius: "4px",
  },
  reviewContainer: {
    backgroundColor: "var(--trade-body)",
    height: "auto",
    borderRadius: "4px",
  },
  safetyLibraryRow: {
    border: "1px solid var(--trade-neutral)",
    maxHeight: "50px",
  },
  safetyLibraryTable: {
    border: "1px solid var(--trade-neutral)",
    height: "100%",
    overflowY: "scroll",
    position: "relative",
  },
  taskBox: {
    border: "1px solid var(--trade-neutral)",
  },
  tasksAndMeetingsTable: {
    border: "1px solid var(--trade-neutral)",
  },
  tasksAndMeetingsRow: {
    border: "1px solid var(--trade-neutral)",
    minHeight: "5rem",
  },
  addedTaskRow: {
    backgroundColor: "var(--trade-boackground)",
    height: "auto",
    borderRadius: "4px",
    //border: "1px solid var(--trade-neutral)",
    margin: "1rem auto",
  },
  workerItem: {
    //padding: "0.7rem",
  },
  addWorkerItem: {
    borderTop: "1px solid #555",
    //padding: "0.7rem",
    //backgroundColor: "#ddd",
  },
  subtitle: {
    textDecoration: "underline",
  },
  mapPlaceholder: {
    height: "75%",
    color: "var(--trade-font)",
    fontSize: "2rem",
    margin: "auto",
    padding: "1rem",
    textAlign: "center",
  },
  meetingsBox: {
    height: "14rem",
    overflowY: "scroll",
  },
  addedTaskContainer: {
    borderRadius: "4px",
    backgroundColor: "#4d4c4b",
    margin: "0rem 2rem 2rem 2rem",
  },
});

const FormLabel = withStyles({
  label: {
    fontSize: "1.3rem",
    color: "var(--trade-font)",
  },
})(FormControlLabel);

const RegularMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={props.zoom}
      center={{ lat: props.lat, lng: props.lng }}
      defaultOptions={{
        scrollwheel: false,
      }}

      //height="100%"
    >
      <Circle
        center={{ lat: props.lat, lng: props.lng }}
        radius={props.radius}
        visible={true}
        draggable={true}
        onDragEnd={props.onDragEnd}
      />
    </GoogleMap>
  ))
);

const WhiteTextTypography = withStyles({
  root: {
    color: "var(--trade-font)",
  },
})(Typography);

const BlackTextTypography = withStyles({
  root: {
    color: "var(--trade-background)",
  },
})(Typography);

const RedTextTypography = withStyles({
  root: {
    color: "#FF0000",
  },
})(Typography);

const GreyAppBar = withStyles({
  root: {
    backgroundColor: "var(--trade-body)",
    borderRadius: "4px",
  },
})(AppBar);

const GreenTabs = withStyles({
  indicator: {
    color: "var(--trade-success)",
    backgroundColor: "var(--trade-success)",
  },
})(Tabs);

const GreenCheckbox = withStyles({
  root: {
    color: "var(--trade-success)",
    "&$checked": {
      color: "var(--trade-success)",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class AddProjectPage extends React.Component {
  classes = this.props.classes;
  lastDiv = React.createRef();

  state = {
    title: "",
    radius: 250,
    createdBy: "",
    number: "",
    address: "",
    street_address: "",
    city: "",
    province: "",
    postal_code: "",
    startDate: "",
    endDate: "",
    projectManager: "",
    clientEmail: "",
    autoStartGeofence: true,
    budget: "",
    company: "",
    latitude: "",
    longitude: "",
    max_radius: 600,
    min_radius: 100,
    newItem: true,
    item: null,
    loading: true,
    step: 0,
    safetyMeetingActiveTab: 0,
    hiddenSafetyMeetingSection: true,
    adminTasks: [],
    clientEmail: "",
    createMeeting: false,
    currentQuestionTitle: "",
    currentQuestionType: "",
    currentQuestionOption: "",
    currentQuestionOptions: "",
    currentQuestionOptionList: [],
    currentTask: {},
    currentInfo: [],
    dragActive: false,
    draggedInfo: [],
    drawerOpen: false,
    dailyMeetingSearch: "",
    editedTask: "",
    editedTaskInfos: [],
    infoDeleted: false,
    isEditingTask: false,
    meetingCode: "",
    meetingTitle: "",
    meetingFrequency: "",
    meetingDescription: "",
    meetingDaily: false,
    meetingToolbox: false,
    newInfo: {},
    newTaskTitle: "",
    noTaskError: "",
    currentFieldForms: [],
    tasks: [],
    searchValue: "",
    selectedInfo: "",
    selectedInfos: [],
    selectedTask: "",
    selectedTasks: [],
    taskAdded: false,
    tmError: "",
    toolboxMeetingSearch: "",
    workerOptions: [],
    workerDialogOpen: false,
    taskExplanation: "",
    showSafetyMeetingLists: false,
    errorMessage: "",
    snackOpen: false,
    autocompleteKey: "",
    projectManagerName: "",
    // removedInfos: []
  };

  possibleQuestionTypes = [
    { value: "string", name: "Text" },
    { value: "choices", name: "Choice" },
  ];

  stepExplanations = {
    // selectTask: "Select a task that you would like add to your project",
    // selectTask:
    //   "Note:" +
    //   "By default, this project already has the current tasks assinged:" +
    //   "Estimating" +
    //   "Project Management" +
    //   "Administration Office",
    selectSafetyMeetings:
      "Check all the safety meetings that you want to associate with your task." +
      "These will have to be read by the worker when they log in and select that task.",
    taskNotSelectedError: "Please enter a name for the task",
    noMeetingsSelectedError: "Please select at least 1 safety meeting",
    taskAlreadySelected:
      "This task is already selected, please select another one.",
    infoAlreadySelected:
      "This safety meeting is already selected, please select another one.",
  };
  /************************************* handle input/state change  *****************************************/

  componentDidMount() {
    const item_id = this.props.match.params.id;

    var company;
    company = this.props.current_company;
    if (item_id !== undefined) {
      this.props.getProjectDetails(item_id).then((res) => {
        const startDate = new Date(res.item.date_created);
        const endDate = new Date(res.item.expected_completion);
        const startDateString = `${startDate.getFullYear()}-${
          startDate.getMonth() + 1 < 10 ? "0" : ""
        }${startDate.getMonth() + 1}-${startDate.getDate()}`;
        const endDateString = `${endDate.getFullYear()}-${
          endDate.getMonth() + 1 < 10 ? "0" : ""
        }${endDate.getMonth() + 1}-${endDate.getDate()}`;

        company = this.props.current_company
          ? this.props.current_company
          : res.item.company;
        this.props.getCompany(company);

        this.props.getCompanySafetyMeetingInfos(company).then((response) => {
          if (response !== null && response !== undefined) {
            const tasks = res.item.tasks.filter((task) => !task.is_admin_task);
            // each task in the project, has a list of safety_meeting_info ids.
            // this converts list of ids into list of safety_meeting_info objects for each task
            tasks.forEach((elem) => {
              const meeting_objects = elem.safety_meeting_infos.map(
                (meeting_id) =>
                  response.items.filter((item) => item.id === meeting_id)[0]
              );
              elem.safety_meeting_infos = meeting_objects;
            });
            this.setState({
              selectedTasks: tasks,
            });
          }
        });

        this.initLocation(res.item.address);
        this.setState({
          newItem: false,
          item: res.item,
          company: res.item.company,
          title: res.item.title,
          number: res.item.job_number,
          address: res.item.address,
          street_address: res.item.street_address,
          city: res.item.city,
          province: res.item.province,
          country: res.item.country,
          postal_code: res.item.postal_code,
          clientEmail: res.item.client_email,
          autoStartGeofence: res.item.auto_start_geofence,
          latitude: res.item.latitude,
          longitude: res.item.longitude,
          budget: res.item.budget,
          radius: res.item.radius,
          projectManagerName: `${res.item.project_manager?.first_name} ${res.item.project_manager?.last_name}`,
          projectManager: res.item.project_manager.id,
          startDate: startDateString,
          endDate: endDateString,
          step: 2,
          currentQuestionType: this.possibleQuestionTypes[0].value,
          fieldsFilled: true,
        });
      });
    } else {
      this.props.getCompany(company);
      this.setState({
        newItem: true,
        company: company,
        loading: false,
        currentQuestionType: this.possibleQuestionTypes[0].value,
        year: new Date().getFullYear(),
      });
      this.props.getCompanySafetyMeetingInfos(company);
      // this.filterInfos();
    }

    this.props.getCompanyWorkers(company).then((elem) => {
      if (elem) {
        const options = [
          ...elem.items,
          {
            user: { id: "new-worker", first_name: "New", last_name: "Worker" },
          },
          {
            user: {
              id: "none-selected",
              first_name: "None",
              last_name: "Selected",
            },
          },
        ];
        this.setState({
          workerOptions: options,
        });
        if (item_id === undefined) {
          this.setState({
            projectManager: this.props.user.id,
            projectManagerName: `${this.props.user.first_name} ${this.props.user.last_name}`,
          });
        }
      }
    });

    // just to show some suggestions of task types
    ax.get("/api/tasks-types/?company=" + company)
      .then((res) => {
        if (res && res.data && res.data.results !== undefined) {
          this.setState({
            tasks: res.data.results.filter((task) => !task.is_admin_task),
            adminTasks: res.data.results.filter(
              (task) => task.is_admin_task && task.title !== "Site Worker"
            ),
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      safetyMeetingActiveTab: newValue,
    });
  };

  handleTextInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleCheckboxChange = (event) => {
    // This converts any value to a boolean
    const oldValue = this.state[event.target.name];
    this.setState({
      [event.target.name]: !oldValue,
    });
  };

  handleProjectManagerChange = (event) => {
    if (event.target.value === "new-worker") {
      this.setState({
        workerDialogOpen: true,
      });
    }

    if (event.target.value === "none-selected") {
      this.setState({
        projectManagerName: "None Selected",
        projectManager: null,
      });
    }

    const pmObject = this.state.workerOptions.filter(
      (e) => e.user.id === event.target.value
    )[0];
    if (pmObject !== undefined) {
      this.setState({
        projectManagerName: `${pmObject.user.first_name} ${pmObject.user.last_name}`,
        projectManager: pmObject.user.id,
      });
    }
  };

  onDragEnd(e) {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    this.setState({ latitude: lat, longitude: lng });
    return { lat, lng };
  }

  initLocation = (address) => {
    fetch(
      "https://www.mapquestapi.com/geocoding/v1/address?key=" +
        process.env.MAPQUEST_API_KEY +
        "&location=" +
        address
    )
      .then((response) => response.json())
      .then((response) => {
        return {
          lat: response.results[0].locations[0].latLng.lat,
          lng: response.results[0].locations[0].latLng.lng,
        };
      })
      .then((response) =>
        this.setState({ latitude: response.lat, longitude: response.lng })
      );
  };

  handleEnterAddress = (ev) => {
    if (ev.key === "Enter") {
      this.searchAddress();
    }
  };

  searchAddress = () => {
    if (this.state.address === "") {
      this.setState({
        snackOpen: true,
        errorMessage: "Project Address can't be empty.",
      });
      return false;
    }

    fetch(
      "https://www.mapquestapi.com/geocoding/v1/address?key=" +
        process.env.MAPQUEST_API_KEY +
        "&location=" +
        this.state.address
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        return {
          lat: response.results[0].locations[0].latLng.lat,
          lng: response.results[0].locations[0].latLng.lng,
          street_address: response.results[0].locations[0].street,
          city: response.results[0].locations[0].adminArea5,
          province: response.results[0].locations[0].adminArea3,
          country: response.results[0].locations[0].adminArea1,
          postal_code: response.results[0].locations[0].postalCode,
        };
      })
      .then((response) =>
        this.setState({
          latitude: response.lat,
          longitude: response.lng,
          street_address: response.street_address,
          city: response.city,
          province: response.province,
          country: response.country,
          postal_code: response.postal_code,
          fieldsFilled: true,
        })
      );
  };

  changeRadius(value) {
    if (
      this.state.radius + value > this.state.max_radius ||
      this.state.radius + value < this.state.min_radius
    )
      return;
    this.setState({ radius: this.state.radius + value });
  }

  useMyLocation() {
    this.setState({
      latitude: this.state.coords.latitude,
      longitude: this.state.coords.longitude,
    });
  }

  validateProjectInfo = () => {
    if (this.state.title === "") {
      this.setState({
        snackOpen: true,
        errorMessage: "Project Title can't be empty.",
      });
      return false;
    }

    if (this.state.address === "") {
      this.setState({
        snackOpen: true,
        errorMessage: "Project Address can't be empty.",
      });
      return false;
    }

    // if (this.state.startDate === "") {
    //   this.setState({
    //     snackOpen: true,
    //     errorMessage: "Project Start Date can't be empty.",
    //   });
    //   return false;
    // }

    // if (this.state.endDate === "") {
    //   this.setState({
    //     snackOpen: true,
    //     errorMessage: "Project End Date can't be empty.",
    //   });
    //   return false;
    // }

    // Uncomment to make Budget a required field
    /* 
    if (this.state.budget === "") {
      this.setState({
        snackOpen: true,
        errorMessage: "Project Budget can't be empty.",
      });
      return false;
    }

    if (this.state.budget === "") {
      this.setState({
        snackOpen: true,
        errorMessage: "You have to select a Project Manager",
      });
      return false;
    }
    */

    let budgetRegex = new RegExp(/^$|(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/);
    if (!budgetRegex.test(this.state.budget)) {
      this.setState({
        snackOpen: true,
        errorMessage: "Budget field only accepts numbers or decimals",
      });
      return false;
    }

    // Uncomment to validate email
    // let regex = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);
    // if (!regex.test(this.state.clientEmail)) {
    //   console.log("email failed");
    //   this.setState({
    //     snackOpen: true,
    //     errorMessage: "You must enter a valid email",
    //   });
    //   return false;
    // }

    return true;
  };

  updateStep = () => {
    if (this.state.step === 0) {
      //validate input
      if (this.validateProjectInfo()) {
        this.setState({
          step: this.state.step + 1,
          projectInfoError: "",
          taskExplanation: this.stepExplanations.selectTask,
          fieldsFilled: false,
        });
      }
    } else if (this.state.step === 1) {
      this.createTask();
      console.log(this.state);
      this.setState({
        noTaskError: "",
        step: this.state.step + 1,
      });
    }
  };

  scrollToLastDiv = () => {
    this.lastDiv.current.scrollIntoView({ behavior: "smooth" });
  };

  editTask = (title, task) => {
    this.setState({
      editedTask: task,
      editedTaskInfos: task.safety_meeting_infos,
      selectedTask: title,
      showSafetyMeetingLists: true,
      taskExplanation: this.stepExplanations.selectSafetyMeetings,
    });
  };

  cancelEditTask = () => {
    this.setState({
      selectedTask: "",
      showSafetyMeetingLists: false,
      taskExplanation: "",
    });
  };

  TaskAutoComplete = () => {
    return (
      <Autocomplete
        id="free-solo-demo"
        key={this.state.autocompleteKey}
        freeSolo
        //options={this.state.tasks.map((option) => option.title)}
        options={this.state.tasks}
        getOptionLabel={(option) => option.title}
        onChange={this.handleTaskChange}
        onInputChange={this.handleTaskSelection}
        onKeyDown={(e) => {
          if (
            (e.code === "Enter" && e.target.value && !this.state.taskAdded) ||
            (e.code === "Tab" && e.target.value && !this.state.taskAdded)
          ) {
            this.selectTask(e);
          }
          if (e.code === "Enter" && e.target.value && !this.state.taskAdded) {
            // Small quirks about the Enter event not sending the data
            // properly in the onInputChange event.
            this.handleTaskSelection(e, e.target.value, "notReset");
          }
        }}
        name="selectedTask"
        inputValue={this.state.selectedTask}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          paddingLeft: "10px",
        }}
        renderInput={(params) => (
          <CssTextField
            {...params}
            InputLabelProps={{ shrink: false }}
            placeholder="Type in task name"
            value={this.state.selectedTask}
            variant="standard"
            name="selectedTask"
          />
        )}
      />
    );
  };

  SafetyMeetingAutoComplete = () => {
    const filter = createFilterOptions();

    let filteredSafetyLibrary = this.props.safety_meeting_infos.filter(
      (meeting) =>
        !this.state.currentInfo.some(
          (currentMeeting) => meeting.id === currentMeeting.id
        )
    );

    return (
      <Autocomplete
        id="free-solo-demo"
        key={this.state.autocompleteKey}
        freeSolo
        options={filteredSafetyLibrary}
        getOptionLabel={(option) => option.title}
        onChange={(event, value) => this.setState({ newInfo: value })}
        onInputChange={this.handleMeetingSelection}
        onKeyDown={(e) => {
          if (
            (e.code === "Enter" && e.target.value) ||
            (e.code === "Tab" && e.target.value)
          ) {
            this.selectInfo();
            this.setState({ dailyMeetingSearch: "" });
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.title
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              title: `Create new safety meeting.`,
            });
          }

          return filtered;
        }}
        name="dailyMeetingSearch"
        inputValue={this.state.dailyMeetingSearch}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          paddingLeft: "10px",
        }}
        renderInput={(params) => (
          <CssTextField
            {...params}
            InputLabelProps={{ shrink: false }}
            placeholder="Type in safety meeting name or drag from right column."
            value={this.state.dailyMeetingSearch}
            variant="standard"
            name="dailyMeetingSearch"
          />
        )}
      />
    );
  };

  // handles changes on the controlled components

  handleTaskSelection = (event, value, reason) => {
    if (reason !== "reset") {
      if (value === "") {
        this.setState({
          showSafetyMeetingLists: false,
          taskExplanation: this.stepExplanations.selectTask,
          taskAdded: false,
          currentInfo: [],
        });
      }

      this.setState({
        selectedTask: value,
        currentTask: this.state.tasks.filter((elem) => elem.title === value),
      });
    }
  };

  handleMeetingSelection = async (_, value, __) => {
    if (value === "") {
      this.setState({
        // showSafetyMeetingLists: false,
        taskExplanation: this.stepExplanations.selectSafetyMeetings,
      });
    }
    this.setState({
      dailyMeetingSearch: value,
    });

    if (value === "Create new safety meeting.") {
      this.setState({ drawerOpen: true, dailyMeetingSearch: "" });
    }
  };

  setDrawerOpen = (value) => {
    this.setState({ drawerOpen: value });
  };

  setSelectedTasks = (resValue) => {
    this.setState({
      selectedTasks: resValue,
      infoDeleted: !this.state.infoDeleted,
    });
  };

  setNewTaskTitle = (value) => {
    this.setState({ newTaskTitle: value });
  };

  // user clicks on task "+" button
  selectTask = (value) => {
    // check if task was already selected. if yes, show error message, else show safety meeting list
    if (value.title !== "" && value.safety_meeting_infos) {
      const filtered = this.state.selectedTasks.filter((elem) => {
        return elem.title === value.title;
      });
      if (filtered.length > 0) {
        this.setState({
          snackOpen: true,
          errorMessage: this.stepExplanations.taskAlreadySelected,
        });
      } else {
        let result = this.props.safety_meeting_infos.filter((o1) =>
          value.safety_meeting_infos.some((o2) => o1.id === o2)
        );
        this.setState({
          currentInfo: result,
          taskAdded: true,
          showSafetyMeetingLists: true,
          taskExplanation: this.stepExplanations.selectSafetyMeetings,
        });
      }
    } else {
      if (this.state.selectedTask !== "") {
        const filtered = this.state.selectedTasks.filter((elem) => {
          return elem.title === this.state.selectedTask;
        });
        if (filtered.length > 0) {
          this.setState({
            snackOpen: true,
            errorMessage: this.stepExplanations.taskAlreadySelected,
          });
        } else {
          this.setState({
            taskAdded: true,
            showSafetyMeetingLists: true,
            taskExplanation: this.stepExplanations.selectSafetyMeetings,
          });
        }
      } else {
        this.setState({
          taskAdded: false,
          snackOpen: true,
          errorMessage: this.stepExplanations.taskNotSelectedError,
        });
      }
      this.filterInfos();
    }
  };

  // user clicks on meeting "+" button
  selectInfo = () => {
    this.setState({ dailyMeetingSearch: "" });
    this.filterInfos(this.state.newInfo);
  };

  showSafetyMeetingSection = async (event) => {
    this.setState({ hiddenSafetyMeetingSection: false });

    if (event?.target.name === "add-daily-meeting") {
      this.setState({
        meetingDaily: true,
        meetingToolbox: false,
      });
    } else if (event?.target.name === "add-toolbox-meeting") {
      this.setState({
        meetingDaily: false,
        meetingToolbox: true,
      });
    }

    await setTimeout(function () {
      console.log("hello");
    }, 500);
    // this.scrollToLastDiv();
  };

  addAnswerToFieldForm = () => {
    if (this.state.currentQuestionOption === "") {
      this.setState({
        snackOpen: true,
        errorMessage: "Option cannot be empty",
      });
    } else {
      const ls = this.state.currentQuestionOptionList.filter(
        (elem) =>
          elem.toLowerCase() === this.state.currentQuestionOption.toLowerCase()
      );

      if (ls.length > 0) {
        this.setState({
          snackOpen: true,
          errorMessage: "Option already added",
        });
      } else {
        var elm =
          this.state.currentQuestionOptions +
          this.state.currentQuestionOption +
          "|" +
          this.state.currentQuestionOption +
          "\r\n";

        this.setState({
          currentQuestionOptions: elm,
          currentQuestionOptionList: [
            ...this.state.currentQuestionOptionList,
            this.state.currentQuestionOption,
          ],
          currentQuestionOption: "",
        });
      }
    }
  };

  handleSafetyMeetingChecked = async (event) => {
    const meeting = this.props.safety_meeting_infos.filter(
      (item) => item.id === event.target.name
    )[0];
    if (meeting !== undefined) {
      this.setState({
        fieldsFilled: true,
      });
      if (this.state.selectedInfos.includes(meeting)) {
        const remv = this.state.selectedInfos.filter(
          (item) => item.id !== meeting.id
        );
        this.setState({
          selectedInfos: remv,
        });
      } else if (this.state.editedTaskInfos.includes(meeting)) {
        const remv = this.state.editedTaskInfos.filter(
          (item) => item.id !== meeting.id
        );
        this.setState({
          editedTaskInfos: remv,
        });
      } else {
        this.setState({
          selectedInfos: [...this.state.selectedInfos, meeting],
          editedTaskInfos: [...this.state.editedTaskInfos, meeting],
        });
      }
    }
    await setTimeout(function () {
      console.log("waiting");
    }, 1000);
  };

  filterInfos = (newValue, removeValue) => {
    let currentInfoArr = [...this.state.currentInfo];
    for (let info in this.props.safety_meeting_infos) {
      for (let currentInfo in this.state.currentTask[0]?.safety_meeting_infos) {
        if (
          this.props.safety_meeting_infos[info].id ===
          this.state.currentTask[0].safety_meeting_infos[currentInfo]
        ) {
          currentInfoArr.push(this.props.safety_meeting_infos[info]);
        }
      }
    }
    this.setState({
      currentInfo: [...currentInfoArr],
    });
    if (newValue) {
      this.setState({ currentInfo: [...this.state.currentInfo, newValue] });
    }
    if (removeValue) {
      this.setState({
        currentInfo: this.state.currentInfo.filter(
          (info) => info.id !== removeValue.id
        ),
        infoDeleted: true,
      });
    }
  };

  handleSafetyMeetingDelete = (taskTypeId, safetyMeetingId, isAdmin) => {
    this.props
      .removeSafetyMeetingFromTask(taskTypeId, safetyMeetingId)
      .then((res) => {
        if (res.type !== "DATA_ERROR_SAVING") {
          if (isAdmin) {
            const adminTasksCopy = [...this.state.adminTasks];
            const task = adminTasksCopy.filter(
              (task = {}) => task.id === taskTypeId
            )?.[0];

            const taskIndex = adminTasksCopy.findIndex(
              (task = {}) => task.id === taskTypeId
            );

            const { safety_meeting_infos: safetyMeetingInfo } = task;

            const updatedSafetyMeetingInfo = safetyMeetingInfo.filter(
              (info = {}) => info !== safetyMeetingId
            );

            const updatedTask = {
              ...task,
              safety_meeting_infos: updatedSafetyMeetingInfo,
            };

            adminTasksCopy[taskIndex] = updatedTask;
            this.setState({
              infoDeleted: !this.state.infoDeleted,
              adminTasks: adminTasksCopy,
            });
          } else {
            const selectedTasksCopy = [...this.state.selectedTasks];
            const task = selectedTasksCopy.filter(
              (task = {}) => task.id === taskTypeId
            )?.[0];

            const taskIndex = selectedTasksCopy.findIndex(
              (task = {}) => task.id === taskTypeId
            );

            const { safety_meeting_infos: safetyMeetingInfo } = task;

            const updatedSafetyMeetingInfo = safetyMeetingInfo.filter(
              (info = {}) => info.id !== safetyMeetingId
            );

            const updatedTask = {
              ...task,
              safety_meeting_infos: updatedSafetyMeetingInfo,
            };

            selectedTasksCopy[taskIndex] = updatedTask;
            this.setState({
              infoDeleted: !this.state.infoDeleted,
              selectedTasks: selectedTasksCopy,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          snackOpen: true,
          errorMessage: "Can't remove task, please try again",
        });
      });
  };

  handleRemoveTask = (taskId, isAdmin) => {
    if (isAdmin) {
      const adminTasksCopy = [...this.state.adminTasks];

      const taskIndex = adminTasksCopy.findIndex(
        (task = {}) => task.id === taskId
      );

      const updatedTasks = adminTasksCopy.splice(taskIndex, 1);

      this.setState({
        infoDeleted: !this.state.infoDeleted,
        adminTasks: adminTasksCopy,
      });
    } else {
      const selectedTasksCopy = [...this.state.selectedTasks];

      const taskIndex = selectedTasksCopy.findIndex(
        (task = {}) => task.id === taskId
      );

      const updatedTasks = selectedTasksCopy.splice(taskIndex, 1);

      this.setState({
        infoDeleted: !this.state.infoDeleted,
        selectedTasks: selectedTasksCopy,
      });
    }
  };

  handleNewTaskTitle = (taskId) => {
    const selectedTasksCopy = [...this.state.selectedTasks];

    const taskIndex = selectedTasksCopy.findIndex(
      (task = {}) => task.id === taskId
    );

    const editedTask = selectedTasksCopy[taskIndex];

    const updatedTask = { ...editedTask, title: this.state.newTaskTitle };

    selectedTasksCopy[taskIndex] = updatedTask;

    this.setState({
      infoDeleted: !this.state.infoDeleted,
      selectedTasks: selectedTasksCopy,
      isEditingTask: false,
    });
  };

  handleTaskChange = (event, value, reason) => {
    if (value) {
      this.handleTaskSelection(event, value.title, "taskSelect");
    }
    if (reason === "select-option") {
      this.selectTask(value);
    }
  };

  meetingSelected = (meetingId) => {
    const ids = this.state.selectedInfos?.map((elem) => elem.id);

    const addedSafetyMeetings = this.state.editedTaskInfos.map(
      (elem) => elem.id
    );

    return ids?.includes(meetingId) || addedSafetyMeetings.includes(meetingId);
  };

  closeMeetingContainer = () => {
    this.setState({
      hiddenSafetyMeetingSection: true,
      clientEmail: "",
      currentQuestionTitle: "",
      currentQuestionType: "",
      currentQuestionOption: "",
      currentQuestionOptions: "",
      currentQuestionOptionList: [],
      meetingCode: "",
      meetingTitle: "",
      meetingFrequency: "",
      meetingDescription: "",
      meetingDaily: false,
      currentFieldForms: [],
    });
  };

  handleProjectInfoEdit = () => {
    this.setState({
      step: 0,
    });
  };

  closeTaskOption = () => {
    //change key of autocomplete to re-render and clear value
    const num = Math.random().toString();

    this.setState({
      taskExplanation: this.stepExplanations.selectTask,
      selectedInfos: [],
      selectedTask: "",
      autocompleteKey: num,
      showSafetyMeetingLists: false,
    });
  };

  handleTaskEdit = () => {
    this.setState({
      taskExplanation: this.stepExplanations.selectTask,
      step: 1,
    });
  };

  /********************************************* Backend interactions ***************************************/
  // stopTimeLog = async () => {
  //     const {latitude, longitude} = this.state;
  //     if (this.props.user.last_time_log !== null){
  //         const timelogId = this.props.user.last_time_log.id;
  //         this.props.stopTimer(timelogId, latitude, longitude);
  //     }
  // }

  workerCreation = async (data) => {
    const worker = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      password: data.password,
      company: this.state.company,
      isActive: true,
      isManager: true,
      role: "MANAGER",
    };

    await this.props.createWorker(worker).then((res) => {
      if (res.type === "DATA_SAVED") {
        this.props
          .getCompanyWorkers(this.props.current_company)
          .then((elem) => {
            if (elem) {
              const options = [
                ...elem.items,
                {
                  user: {
                    id: "new-worker",
                    first_name: "New",
                    last_name: "Worker",
                  },
                },
                {
                  user: {
                    id: "none-selected",
                    first_name: "None",
                    last_name: "Selected",
                  },
                },
              ];
              this.setState({
                workerOptions: options,
                workerDialogOpen: false,
                projectManager: res.item.id,
                projectManagerName: `${res.item.first_name} ${res.item.last_name}`,
              });
            }
          });
      } else if (res.error) {
        this.setState({
          snackOpen: true,
          errorMessage: res.error.toJSON().message,
        });
      } else {
        this.setState({
          snackOpen: true,
          errorMessage: "Unable to create worker. Please try again later.",
        });
      }
    });
  };

  createTask = () => {
    if (this.state.selectedTask !== "") {
      // every task must have at least one safety meeting
      if (this.state.currentInfo.length >= 1) {
        this.props
          .createTask(this.state.company, this.state.selectedTask)
          .then((elem) => {
            if (elem) {
              elem.item.safety_meeting_infos = this.state.currentInfo;

              // makes the backend association
              this.addSafetyMeetingInfosToTask(elem.item);

              //change key of autocomplete to re-render and clear value
              const num = Math.random().toString();
              //clean state
              this.setState({
                tasks: [...this.state.tasks, elem.item],
                selectedTasks: [...this.state.selectedTasks, elem.item],
                taskExplanation: this.stepExplanations.selectTask,
                currentInfo: [],
                selectedTask: "",
                autocompleteKey: num,
                showSafetyMeetingLists: false,
                taskAdded: false,
              });
            }
          });
      } else {
        this.setState({
          //taskExplanation: this.stepExplanations.noMeetingsSelectedError,
          snackOpen: true,
          errorMessage: this.stepExplanations.noMeetingsSelectedError,
        });
      }
    } else {
      this.setState({
        //taskExplanation: this.stepExplanations.taskNotSelectedError,
        snackOpen: true,
        errorMessage: this.stepExplanations.taskNotSelectedError,
      });
    }
  };

  removeTaskFromProject = (event) => {
    // only if a project already exists
    if (!this.state.newItem && this.state.item.id !== undefined) {
      const taskId = event.target.name;
      this.props.removeTaskFromProject(taskId, this.state.item.id);
    }

    const updatedTasks = this.state.selectedTasks.filter(
      (item) => item.id !== event.target.name
    );
    this.setState({
      selectedTasks: updatedTasks,
    });
  };

  removeAdminTaskFromProject = (event) => {
    // only if a project already exists
    if (!this.state.newItem && this.state.item.id !== undefined) {
      const taskId = event.target.name;
      this.props.removeTaskFromProject(taskId, this.state.item.id);
    }

    const updatedTasks = this.state.adminTasks.filter(
      (item) => item.id !== event.target.name
    );
    this.setState({
      adminTasks: updatedTasks,
    });
  };

  addSafetyMeetingInfosToTask = (task) => {
    for (var i = 0; i < task.safety_meeting_infos.length; i++) {
      this.props
        .addSafetyMeetingToTask(task.id, task.safety_meeting_infos[i].id)
        .then((item) => {
          console.log(item);
        });
    }
  };

  createMeeting = async () => {
    // check that all fields are filled
    if (
      this.state.meetingTitle !== "" &&
      this.state.meetingCode !== "" &&
      this.state.meetingDescription !== "" &&
      this.state.company !== ""
    ) {
      const data = {
        company: this.state.company,
        title: this.state.meetingTitle,
        code: this.state.meetingCode,
        description: this.state.meetingDescription,
        daily: this.state.meetingDaily,
        toolbox: this.state.meetingToolbox,
        library: false,
        files: [],
        //task_type_id: null,
        clientEmail: this.state.clientEmail,
      };

      // action that creates safety meeting on the backend
      // and dispatches result to be added to the store
      this.props.createSafetyMeetingInfo(data).then((e) => {
        console.log(e);
        // if the creation was successful
        if (e && e.type === "CREATE_SAFETY_MEETING") {
          // add fieldforms to the safety meeting we just created
          for (var i = 0; i < this.state.currentFieldForms.length; i++) {
            this.props
              .createFieldForm({
                order: 10,
                title: this.state.currentFieldForms[i].title,
                type: this.state.currentFieldForms[i].type,
                options: this.state.currentFieldForms[i].options,
                safety_meeting_info: e.item.id,
              })
              .then((elem) => {
                console.log(elem);
              });
          }

          // clean state
          this.setState({
            clientEmail: "",
            hiddenSafetyMeetingSection: true,
            meetingCode: "",
            meetingTitle: "",
            meetingDescription: "",
            meetingDaily: false,
            currentFieldForms: [],
            //currentFieldForms: [],
          });
        } else {
          this.setState({
            snackOpen: true,
            errorMessage:
              "Error creating Safety Meeting. Please try again later.",
          });
        }
      });
    } else {
      this.setState({
        snackOpen: true,
        errorMessage:
          "Please add title, code and description for the safety meeting.",
      });
    }
  };

  addFieldFormToSafetyMeeting = () => {
    if (this.state.currentQuestionTitle === "") {
      this.setState({
        snackOpen: true,
        errorMessage: "Question Title cannot be empty",
      });
    } else {
      if (
        (this.state.currentQuestionType === "choices" ||
          this.state.currentQuestionType === "multi") &&
        this.state.currentQuestionOptionList.length < 2
      ) {
        this.setState({
          snackOpen: true,
          errorMessage:
            "At least 2 options need to be added for this type of question",
        });
      } else {
        this.setState({
          currentFieldForms: [
            ...this.state.currentFieldForms,
            {
              order: "10",
              title: this.state.currentQuestionTitle,
              type: this.state.currentQuestionType,
              options: this.state.currentQuestionOptions,
              isRequired: "false",
            },
          ],
        });

        this.setState({
          currentQuestionTitle: "",
          currentQuestionOptions: "",
          currentQuestionOption: "",
          currentQuestionOptionList: [],
          currentQuestionType: "",
        });
      }
    }
  };

  deleteFieldForm = (ev) => {
    const update = this.state.currentFieldForms.filter(
      (elem) => elem.title !== ev.target.name
    );
    this.setState({
      currentFieldForms: update,
    });
  };

  handleSave = async () => {
    //update project
    // add tasks to project
    // when task is selected, add SMIs to task (on backend too)

    this.props.updateProject(this.state).then(async (res) => {
      if (res && res.item) {
        for (var i = 0; i < this.state.selectedTasks.length; i++) {
          var response = await this.props.addTaskToProject(
            this.state.selectedTasks[i].id,
            res.item.id
          );
          console.log(response);
        }
        this.setState({
          selectedTasks: [],
          step: this.state.step + 1,
        });
      }
    });
  };

  createProject = async () => {
    //contact backend, send project details, receive project object and add it to props or state
    if (!this.validateProjectInfo()) {
      console.log("inside first if");
      this.setState({
        snackOpen: true,
        errorMessage: "Please make sure all of the fields are filled",
      });
    } else {
      this.props.createProject(this.state).then(async (e) => {
        console.log("eeeeeee" + e.type);
        // project was successfully created
        if (e && e.type === "CREATE_PROJECT") {
          for (var i = 0; i < this.state.selectedTasks.length; i++) {
            console.log(`adding task ${this.state.selectedTasks[i]}`);
            // taskType.id, project.id
            var response = await this.props.addTaskToProject(
              this.state.selectedTasks[i].id,
              e.item.id
            );
            console.log(response);
          }

          this.setState({
            selectedTasks: [],
            step: this.state.step + 1,
          });

          // this.stopTimeLog().then((res) => {
          //     this.setState({
          //         selectedTasks: [],
          //         step: this.state.step + 1
          //     })
          // })
        } else {
          this.setState({
            snackOpen: true,
            errorMessage:
              "Unable to create project. Please make sure all fields are correctly filled.",
          });
        }
      });
    }
  };

  /********************************************* view components ***************************************/

  progressIndicator = () => {
    const steps = ["Project Details", "Safety & Tasks", "Review"];

    return (
      <div className={this.classes.root}>
        <Stepper activeStep={this.state.step} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={index} className={this.classes.stepIcon}>
              <StepLabel className={this.classes.stepLabel}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  };

  projectDetails = () => {
    return (
      <Grid container>
        {/**Main Fields for Project */}
        <Grid container item xs={12} lg={3} md={3}>
          <Grid item xs={12}>
            <Box
              mb={1}
              mr={2}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <WhiteTextTypography
                variant="h5"
                component={"span"}
                className={this.classes.stepDescription}
              >
                Project Information
              </WhiteTextTypography>
            </Box>
          </Grid>
          {/**Project Title Field */}
          <Grid item xs={12}>
            <Box
              mb={2}
              mr={2}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                label="Project Title"
                fullWidth={true}
                variant="outlined"
                value={this.state.title}
                onChange={this.handleTextInputChange}
                name="title"
              />
            </Box>
          </Grid>
          {/**Project Number Field */}
          <Grid item xs={12}>
            <Box
              mb={2}
              mr={2}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                label="Project Number"
                fullWidth={true}
                variant="outlined"
                value={this.state.number}
                onChange={this.handleTextInputChange}
                name="number"
              />
            </Box>
          </Grid>

          {/**budget field */}
          <Grid item xs={12}>
            <Box
              mb={2}
              mr={2}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                label="Budget"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <WhiteTextTypography>$</WhiteTextTypography>
                    </InputAdornment>
                  ),
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                fullWidth={true}
                variant="outlined"
                value={this.state.budget}
                onChange={this.handleTextInputChange}
                name="budget"
              />
            </Box>
          </Grid>

          {/**start date */}
          <Grid item xs={12}>
            <Box
              mb={2}
              mr={2}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                id="date"
                label="Start Date"
                type="date"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={this.state.startDate}
                onChange={this.handleTextInputChange}
                name="startDate"
                fullWidth={true}
              />
            </Box>
          </Grid>
          {/**End date */}
          <Grid item xs={12}>
            <Box
              mb={2}
              mr={2}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                id="date"
                label="End Date"
                type="date"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth={true}
                value={this.state.endDate}
                onChange={this.handleTextInputChange}
                name="endDate"
              />
            </Box>
          </Grid>
          {/**Project Manager*/}
          <Grid item xs={12}>
            <Box
              mb={2}
              mr={2}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                value={this.state.projectManager}
                onChange={this.handleProjectManagerChange}
                variant="outlined"
                label="Project Manager"
                select
                fullWidth={true}
              >
                {this.state.workerOptions.map((e) => (
                  <MenuItem
                    key={e.user.id}
                    value={e.user.id}
                    className={
                      e.user.id === "new-worker"
                        ? this.classes.addWorkerItem
                        : this.classes.workerItem
                    }
                  >
                    {e.user.first_name} {e.user.last_name}
                  </MenuItem>
                ))}
              </CssTextField>
              {/* </FormControl> */}
            </Box>
            <CustomizedDialogs
              open={this.state.workerDialogOpen}
              handleClose={() =>
                this.setState({ workerDialogOpen: false, projectManager: "" })
              }
              handleWorkerCreation={this.workerCreation}
            />
          </Grid>
          {/**Client email Field */}
          <Grid item md={12}>
            <Box
              mb={2}
              mr={2}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                label="Safety Meeting Recipient"
                fullWidth={true}
                variant="outlined"
                value={this.state.clientEmail}
                onChange={this.handleTextInputChange}
                name="clientEmail"
              />
            </Box>
          </Grid>
          {/** Auto start the timer if the worker crosses the geofence */}
          {/* <Grid item md={12}>
          <FormControlLabel
            control={
              <Checkbox
                onClick={(ev) => this.handleCheckboxChange(ev)}
                name="autoStartGeofence"
                checked={this.state.autoStartGeofence}
              />
            }
            classes={{
              label: projectStyles.label,
            }}
            label="Auto start/stop the worker's timer when entering or leaving the geofence."
          />
        </Grid> */}
        </Grid>

        {/**location container map and address field + button */}
        <Grid container item xs={12} lg={9} md={9}>
          {/** Container Title */}
          <Grid item xs={12} lg={12} md={12}>
            <Box
              mb={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <WhiteTextTypography
                variant="h5"
                component={"span"}
                className={this.classes.stepDescription}
              >
                Set Project Location
              </WhiteTextTypography>
            </Box>
          </Grid>
          {/** Address Field & search button */}
          <Grid item xs={12} sm={5} md={5} lg={4}>
            <Box mb={2} ml={1}>
              <CssTextField
                label="Address"
                helperText="(Street Name, City, Province)"
                variant="outlined"
                value={this.state.address}
                onChange={this.handleTextInputChange}
                name="address"
                onKeyPress={this.handleEnterAddress}
                FormHelperTextProps={{
                  className: this.classes.helperText,
                }}
              />
              <button
                onClick={this.searchAddress}
                style={{
                  backgroundColor: "green",
                  padding: "1rem 0.7rem",
                  margin: "0rem 0.4rem",
                  fontWeight: "bold",
                  color: "var(--trade-font)",
                  border: "none",
                  borderRadius: "4px",
                }}
              >
                <SearchIcon />
              </button>
            </Box>
          </Grid>
          {/** radius adjustment */}
          <Grid item sm={7} xs={12} md={7} lg={8} style={{ margin: "auto" }}>
            <Box mb={2}>
              <Grid container alignItems="center">
                <Grid item>
                  <Button round justIcon onClick={() => this.changeRadius(-50)}>
                    <RemoveIcon />
                  </Button>
                </Grid>
                <Grid item xs={4} md={10} style={{ margin: "auto" }}>
                  <CustomLinearProgress
                    variant="determinate"
                    color="success"
                    value={
                      ((this.state.radius - this.state.min_radius) /
                        (this.state.max_radius - this.state.min_radius)) *
                      100
                    }
                    className={this.classes.progress}
                  />
                </Grid>
                <Grid item>
                  <Button round justIcon onClick={() => this.changeRadius(50)}>
                    <AddIcon />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {/**Map */}
          <Grid item xs={12} lg={12} md={12} style={{ height: "100%" }}>
            {this.state.latitude !== "" && this.state.longitude !== "" ? (
              <RegularMap
                googleMapURL={process.env.GOOGLE_MAPS_URL}
                loadingElement={<div style={{ height: `100%` }} />}
                lat={this.state.latitude}
                lng={this.state.longitude}
                radius={this.state.radius}
                zoom={15}
                onDragEnd={(e) => this.onDragEnd(e)}
                containerElement={
                  <div
                    style={{
                      height: "73%",
                      borderRadius: "6px",
                      overflow: "hidden",
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            ) : (
              <div className={this.classes.mapPlaceholder}>
                Map will appear as soon as you enter your address
              </div>
            )}
          </Grid>
        </Grid>
        {/**Move to next section button */}
        <Snackbar
          open={this.state.snackOpen}
          autoHideDuration={5000}
          onClose={() => this.setState({ snackOpen: false })}
        >
          <Alert
            onClose={() => this.setState({ snackOpen: false })}
            severity="error"
          >
            {this.state.errorMessage}
          </Alert>
        </Snackbar>
        <Grid item xs={12}>
          <Box
            mt={1}
            mb={1}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <button
              onClick={this.updateStep}
              style={{
                backgroundColor: `${
                  this.state.fieldsFilled ? "green" : "gray"
                }`,
                padding: "0.7rem 2rem",
                fontWeight: "bold",
                color: "var(--trade-font)",
                border: "none",
                borderRadius: "4px",
              }}
              disabled={!this.state.fieldsFilled}
            >
              Next
            </button>
          </Box>
        </Grid>
      </Grid>
    );
  };

  AddMeetingRow = (dragActive) => {
    return (
      <Grid container className={this.classes.tasksAndMeetingsRow}>
        <Grid container item md={12} className={this.classes.tableHeaders}>
          {/* Table Headers */}
          <Grid
            item
            md={4}
            style={{ display: "flex", alignItems: "center", minHeight: "50px" }}
          >
            <Button
              round
              justIcon
              style={{
                width: "1rem",
                height: "1rem",
                minWidth: "1rem",
                marginLeft: "8px",
              }}
              onClick={!this.state.taskAdded ? this.selectTask : undefined}
            >
              <AddIcon />
            </Button>{" "}
            {this.TaskAutoComplete()}
          </Grid>
          {/* {this.state.showSafetyMeetingLists ? ( */}
          <Grid
            className={dragActive ? "drag-active" : ""}
            // className="drag-active"
            item
            // md={this.state.currentInfo.length > 0 ? 7 : 5}
            md={7}
            onDragOver={(event) => this.allowDrop(event)}
            onDrop={(event) => this.handleDrop(event)}
            style={{ alignItems: "center", display: "flex" }}
          >
            {/* {dragActive ? (
                <Box
                  m={2}
                  p={2}
                  style={{
                    backgroundColor: "var(--trade-secondary)",
                    borderRadius: "8px",
                    margin: "0",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <WhiteTextTypography
                    style={{ textAlign: "center", margin: 0 }}
                  >
                    Drop Items Here
                  </WhiteTextTypography>
                </Box>
              ) : ( */}
            <Box
              className={`safety-meetings-chips ${
                dragActive ? "chips-active" : ""
              }`}
              m={2}
              p={2}
              style={{
                backgroundColor: dragActive
                  ? "var(--trade-font)"
                  : "var(--trade-background)",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0",
                width: "100%",
                height: "100%",
                transition: "background-color 0.2s",
              }}
            >
              <div className="safety-chips-container">
                {this.state.currentInfo.map((elem) => (
                  <Chip
                    label={elem.title}
                    variant="outlined"
                    className={this.classes.chip}
                    // onClick={this.handleSafetyMeetingChecked}
                    onDelete={() => this.filterInfos(null, elem)}
                    style={{
                      backgroundColor: "var(--trade-background)",
                      justifyContent: "space-between",
                    }}
                  >
                    {elem.title}
                  </Chip>
                ))}
              </div>
              {dragActive ? (
                <BlackTextTypography>
                  Drop Safety Meetings Here
                </BlackTextTypography>
              ) : (
                <WhiteTextTypography>
                  Drop Safety Meetings Here
                </WhiteTextTypography>
              )}
              {/* <Button
                    round
                    justIcon
                    style={{ width: "1rem", height: "1rem", minWidth: "1rem" }}
                    onClick={
                      this.state.dailyMeetingSearch
                        ? this.selectInfo
                        : undefined
                    }
                  >
                    <AddIcon />
                  </Button>
                  {this.SafetyMeetingAutoComplete()} */}
            </Box>
            {/* )} */}
          </Grid>
          {/* ) : null} */}
          <Grid item md={1}>
            <Box m={2}></Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  allowDrop = (e) => {
    e.preventDefault();
  };

  handleDrop = (e) => {
    e.preventDefault();
    this.selectInfo();
    this.setState({
      dragActive: false,
    });
  };

  handleDragStart = (e) => {
    this.setState({
      newInfo: this.props.safety_meeting_infos.filter(
        (meeting) => meeting.id === e.target.getAttribute("data-id")
      )[0],
    });
  };

  handleMouseEvent = (event) => {
    if (event.type === "mousedown") {
      this.setState({ dragActive: true });
    } else {
      this.setState({
        dragActive: false,
      });
    }
  };

  setNewInfo = (value) => {
    this.setState({ newInfo: value });
  };

  setIsEditingTask = (value) => {
    this.setState({ isEditingTask: value });
  };

  SafetyLibraryTable = (task, allSafetyMeetings) => {
    let filteredSafetyLibrary = allSafetyMeetings
      .filter(
        (meeting) =>
          !this.state.currentInfo.some(
            (currentMeeting) => meeting.id === currentMeeting.id
          )
      )
      .reduce((accumulator, current) => {
        if (!accumulator.find((item) => item.title === current.title)) {
          accumulator.push(current);
        }
        return accumulator;
      }, [])
      .sort((a, b) => a.title.localeCompare(b.title));

    return (
      <Grid container className={this.classes.safetyLibraryTable}>
        <Grid
          container
          item
          md={12}
          className={this.classes.tableHeaders}
          style={{
            backgroundColor: "var(--trade-background)",
            position: "sticky",
            top: "0",
            borderBottom: "1px solid var(--trade-neutral)",
          }}
        >
          {/* Table Headers */}
          <Grid
            item
            md={12}
            // style={{ cursor: "pointer" }}
            // onClick={this.showSafetyMeetingSection}
          >
            <Box
              m={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "56px",
                margin: "0 16px",
              }}
            >
              <CreateSafetyMeetingSlider
                a11yProps={this.a11yProps}
                addAnswerToFieldForm={this.addAnswerToFieldForm}
                addFieldFormToSafetyMeeting={this.addFieldFormToSafetyMeeting}
                clientEmail={this.state.clientEmail}
                currentFieldForms={this.state.currentFieldForms}
                closeMeetingContainer={this.closeMeetingContainer}
                createMeeting={this.createMeeting}
                currentQuestionOption={this.state.currentQuestionOption}
                currentQuestionOptionList={this.state.currentQuestionOptionList}
                currentQuestionType={this.state.currentQuestionType}
                drawerOpen={this.state.drawerOpen}
                handleTabChange={this.handleTabChange}
                handleTextInputChange={this.handleTextInputChange}
                meetingCode={this.state.meetingCode}
                meetingTitle={this.state.meetingTitle}
                safetyMeetingActiveTab={this.state.safetyMeetingActiveTab}
                safetyMeetingDetails={this.safetyMeetingDetails}
                safetyMeetingQuestions={this.safetyMeetingQuestions}
                safetyMeetingSection={this.classes.safetyMeetingSection}
                setDrawerOpen={this.setDrawerOpen}
                questionsHeaderRow={this.questionsHeaderRow}
                questionContentRow={this.questionContentRow}
                visibility={true}
              />
            </Box>
          </Grid>
        </Grid>
        {filteredSafetyLibrary.map((meeting) => {
          return (
            <Grid container className={this.classes.safetyLibraryRow}>
              <Grid
                container
                item
                md={12}
                className={this.classes.tableHeaders}
              >
                {/* Table Headers */}
                <Grid item md={10}>
                  <Box
                    m={2}
                    display="flex"
                    // justifyContent="center"
                    alignItems="center"
                    // margin="0.2rem 1rem"
                    margin="0 16px"
                    minHeight="50px"
                  >
                    <div
                      data-id={meeting.id}
                      draggable
                      onMouseDown={this.handleMouseEvent}
                      onMouseUp={() => this.setState({ dragActive: false })}
                      onDragEnd={this.handleMouseEvent}
                      onDragStart={(event) => this.handleDragStart(event)}
                      style={{
                        color: "var(--trade-font)",
                        cursor: "grab",
                        width: "100%",
                      }}
                    >
                      {meeting.title}
                    </div>
                  </Box>
                </Grid>
                <Grid item md={2}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <Box component="span">
                      <SafetyMeetingSlider
                        meeting={meeting}
                        visibility={true}
                        showEditButton={true}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  /** Task and Safety Meetings */
  tasksAndMeetings = () => {
    return (
      <div>
        <Grid container>
          <Grid item md={4}>
            <Box
              mr={2}
              ml={2}
              display="flex"
              marginLeft="0px !important"
              marginBottom="15px"
            >
              <WhiteTextTypography
                variant="h5"
                className={this.classes.stepDescription}
              >
                Tasks & Safety Meetings
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box mr={2} ml={2} display="flex" justifyContent="flex-end"></Box>
          </Grid>
          <Grid item md={4}>
            <Box mr={2} ml={2} display="flex">
              <WhiteTextTypography
                variant="h5"
                className={this.classes.stepDescription}
              >
                Safety Meeting Library
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              {/* {this.TaskAndMeetingTable(
                this.state.tasks,
                this.props.safety_meeting_infos
              )} */}
              <TaskAndMeetingTable
                adminTasks={this.state.adminTasks}
                allowDrop={this.allowDrop}
                allSafetyMeetings={this.props.safety_meeting_infos}
                autocompleteKey={this.state.autocompleteKey}
                company={this.props.current_company}
                currentInfo={this.state.currentInfo}
                dailyMeetingSearch={this.state.dailyMeetingSearch}
                dragActive={this.state.dragActive}
                createTask={this.createTask}
                filterInfos={this.filterInfos}
                handleMeetingSelection={this.handleMeetingSelection}
                handleRemoveTask={this.handleRemoveTask}
                handleSafetyMeetingDelete={this.handleSafetyMeetingDelete}
                handleNewTaskTitle={this.handleNewTaskTitle}
                infoDeleted={this.state.infoDeleted}
                isEditingTask={this.state.isEditingTask}
                newTaskTitle={this.state.newTaskTitle}
                safety_meeting_infos={this.props.safety_meeting_infos}
                selectInfo={this.selectInfo}
                selectTask={this.selectTask}
                selectedTask={this.state.selectedTask}
                selectedTasks={this.state.selectedTasks}
                setNewInfo={this.setNewInfo}
                setNewTaskTitle={this.setNewTaskTitle}
                setIsEditingTask={this.setIsEditingTask}
                setSelectedTasks={this.setSelectedTasks}
                showSafetyMeetingLists={this.state.showSafetyMeetingLists}
                taskAdded={this.state.taskAdded}
                tasks={this.state.tasks}
                AddMeetingRow={this.AddMeetingRow}
              />
            </Grid>
            <Grid item xs={4} style={{ height: "40vh", marginBottom: "2rem" }}>
              {this.SafetyLibraryTable(
                this.state.tasks,
                this.props.safety_meeting_infos
              )}
            </Grid>
          </Grid>

          <Grid
            container
            item
            md={12}
            // className={this.classes.taskContainer}
          >
            <Grid
              container
              item
              sm={8}
              md={6}
              lg={4}
              className={this.classes.classBox}
            >
              <Grid item sm={10}>
                <Box
                  m={2}
                  p={2}
                  style={{
                    backgroundColor: "var(--trade-background)",
                    borderRadius: "8px",
                  }}
                >
                  {this.state.showSafetyMeetingLists ? (
                    <WhiteTextTypography>
                      {this.state.taskExplanation}
                    </WhiteTextTypography>
                  ) : (
                    <WhiteTextTypography>
                      <p>
                        <b>Note: </b>
                      </p>
                      <p>
                        By default, this project already has the current tasks
                        assinged:
                      </p>
                      <ul style={{ marginLeft: "1rem" }}>
                        <li>Estimating</li>
                        <li>Project Management</li>
                        <li>Administration Office</li>
                      </ul>
                    </WhiteTextTypography>
                  )}
                </Box>
              </Grid>
            </Grid>
            {/**Add Task button */}
            <Grid
              container
              item
              lg={8}
              md={6}
              style={{ justifyContent: "flex-end" }}
            >
              {/* {this.state.showSafetyMeetingLists ? (
                <Grid
                  item
                  md={2}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Grid item sm={4} lg={2} md={7}>
                    <Box
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      display="flex"
                      style={{ gap: "1rem" }}
                    >
                      <button
                        onClick={this.closeTaskOption}
                        style={{
                          backgroundColor: "#F44336",
                          padding: "0.7rem 1rem",
                          marginTop: "2rem",
                          fontWeight: "bold",
                          color: "var(--trade-font)",
                          border: "none",
                          borderRadius: "4px",
                        }}
                      >
                        Close
                      </button>
                      <button
                        onClick={this.createTask}
                        style={{
                          // backgroundColor: `${
                          //   this.state.fieldsFilled ? "green" : "gray"
                          // }`,
                          backgroundColor: "green",
                          padding: "0.7rem 1rem",
                          marginTop: "2rem",
                          fontWeight: "bold",
                          color: "var(--trade-font)",
                          border: "none",
                          borderRadius: "4px",
                        }}
                        // disabled={!this.state.fieldsFilled || this.isEditingTask}
                      >
                        Next
                      </button>
                    </Box>
                  </Grid>
                </Grid>
              ) : null} */}
              {/* {this.state.selectedTasks.length > 0 &&
              !this.state.showSafetyMeetingLists ? ( */}
              <Grid
                item
                sm={4}
                lg={7}
                md={7}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  m={2}
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  style={{ marginRight: "0" }}
                >
                  <button
                    onClick={this.updateStep}
                    style={{
                      backgroundColor: "green",
                      padding: "0.7rem 2rem",
                      fontWeight: "bold",
                      color: "var(--trade-font)",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  >
                    Review Project and Tasks
                  </button>
                </Box>
              </Grid>
              {/* ) : null} */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  addedTasksContainer = () => {
    return (
      <Grid
        container
        item
        sm={12}
        md={12}
        lg={12}
        className={this.classes.addedTaskRow}
      >
        <Grid container item md={12}>
          <Grid item md={3}>
            <Box
              m={2}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <WhiteTextTypography
                component={"span"}
                className={this.classes.subtitle}
              >
                Task Title
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box
              m={2}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <WhiteTextTypography
                component={"span"}
                className={this.classes.subtitle}
              >
                Daily Meetings
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid item md={2}></Grid>
        </Grid>
        {this.addedTaskList()}
      </Grid>
    );
  };

  addedTaskList = () => {
    return (
      <React.Fragment>
        {this.state.adminTasks.map((elem) => (
          <Grid
            container
            item
            md={12}
            key={elem.id}
            className={this.classes.addedTaskContainer}
          >
            <Grid item md={3}>
              <Box
                m={2}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                <WhiteTextTypography component={"span"}>
                  {elem.title}
                </WhiteTextTypography>
              </Box>
            </Grid>
            <Grid container item md={4}>
              {this.props.safety_meeting_infos.map((meeting) =>
                elem.safety_meeting_infos.map((taskMeeting) => {
                  if (meeting.id === taskMeeting) {
                    return (
                      <Grid item md={12} key={elem.id}>
                        <Box
                          m={2}
                          justifyContent="center"
                          alignItems="center"
                          display="flex"
                        >
                          <WhiteTextTypography key={elem.id}>
                            {meeting.title}
                          </WhiteTextTypography>
                        </Box>
                      </Grid>
                    );
                  }
                })
              )}
            </Grid>
            <Grid item md={2}>
              <Box
                m={2}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                {" "}
                <button
                  onClick={this.removeAdminTaskFromProject}
                  style={{
                    backgroundColor: "var(--trade-tertiary)",
                    padding: "0.4rem 1.3rem",
                    fontWeight: "bold",
                    color: "var(--trade-font)",
                    border: "none",
                    borderRadius: "4px",
                  }}
                  name={elem.id}
                >
                  Delete
                </button>
              </Box>
            </Grid>
          </Grid>
        ))}
        {this.state.selectedTasks.map((elem) => (
          <Grid
            container
            item
            md={12}
            key={elem.id}
            className={this.classes.addedTaskContainer}
          >
            <Grid item md={3}>
              <Box
                m={2}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                <WhiteTextTypography component={"span"}>
                  {elem.title}
                </WhiteTextTypography>
              </Box>
            </Grid>
            <Grid container item md={4}>
              {elem.safety_meeting_infos
                // this.state.currentInfo
                // .filter((item) => item?.daily === true)
                .map((elem) => (
                  <Grid item md={12} key={elem.id}>
                    <Box
                      m={2}
                      justifyContent="center"
                      alignItems="center"
                      display="flex"
                    >
                      <WhiteTextTypography key={elem.id}>
                        {elem.title}
                      </WhiteTextTypography>
                    </Box>
                  </Grid>
                ))}
            </Grid>
            {/* <Grid container item md={3}>
              {elem.safety_meeting_infos
                .filter((item) => item?.toolbox === true)
                .map((elem) => (
                  <Grid item md={12} key={elem.id}>
                    <Box
                      m={2}
                      justifyContent="center"
                      alignItems="center"
                      display="flex"
                    >
                      <WhiteTextTypography key={elem.id}>
                        {elem.title}
                      </WhiteTextTypography>
                    </Box>
                  </Grid>
                ))}
            </Grid> */}
            <Grid item md={2}>
              <Box
                m={2}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                {/* {this.state.step === 1 ? (
                  <TaskModifButtons
                    elem={elem}
                    editTask={this.editTask}
                    removeTaskFromProject={this.removeTaskFromProject}
                    createTask={this.createTask}
                    cancelEditTask={this.cancelEditTask}
                  />
                ) : null} */}
                <button
                  onClick={this.removeTaskFromProject}
                  style={{
                    backgroundColor: "var(--trade-tertiary)",
                    padding: "0.4rem 1.3rem",
                    fontWeight: "bold",
                    color: "var(--trade-font)",
                    border: "none",
                    borderRadius: "4px",
                  }}
                  name={elem.id}
                >
                  Delete
                </button>
              </Box>
            </Grid>
          </Grid>
        ))}
      </React.Fragment>
    );
  };

  questionsHeaderRow = (titles) => {
    return (
      <Grid container item md={12}>
        <Grid item md={4} sm={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography variant="h6">
              Question Title
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={4} sm={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography variant="h6">
              Question Type
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={3} sm={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography variant="h6">Options</WhiteTextTypography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  questionContentRow = (data) => {
    const qtype = this.possibleQuestionTypes.filter(
      (elem) => elem.value === data.type
    )[0].name;
    const ls = [];
    data.options.split("\r\n").forEach((elem) => {
      var temp = elem.split("|")[0];
      ls.push(temp);
    });

    let prettyOptions = ls.reduce((prev, next) => prev + ", " + next);
    prettyOptions = prettyOptions.replace(/(^,)|(,$)/g, "");

    return (
      <Grid container item md={12}>
        <Grid item md={4} sm={12}>
          <Box
            mb={1}
            ml={1}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <WhiteTextTypography variant="body2">
              {data.title}
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={4} sm={12}>
          <Box
            mb={1}
            ml={1}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <WhiteTextTypography variant="body2">{qtype}</WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={3} sm={12}>
          <Box
            mb={1}
            ml={1}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <WhiteTextTypography variant="body2">
              {prettyOptions}
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={1} sm={12}>
          <Box mb={1} ml={2}>
            <button
              style={{
                backgroundColor: "var(--trade-tertiary)",
                padding: "0.3rem 0.7rem",
                fontWeight: "bold",
                color: "var(--trade-font)",
                border: "none",
                borderRadius: "4px",
                margin: "auto",
                marginLeft: "0",
              }}
              onClick={this.deleteFieldForm}
              name={data.title}
            >
              Delete
            </button>
          </Box>
        </Grid>
      </Grid>
    );
  };

  reviewHeaderRow = (titles) => {
    return (
      <Grid container item md={12}>
        {titles.map((elem) => (
          <Grid item md={4} sm={12} key={elem}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <WhiteTextTypography variant="h6">{elem}</WhiteTextTypography>
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  reviewContentRow = (data) => {
    return (
      <Grid container item md={12}>
        {data.map((elem) => (
          <Grid item md={4} sm={12} key={elem}>
            <Box
              mb={1}
              ml={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <WhiteTextTypography variant="body2">{elem}</WhiteTextTypography>
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  reviewPage = () => {
    return (
      <React.Fragment>
        <Grid container className={this.classes.reviewContainer}>
          <Grid item md={9}>
            <Box m={2}>
              <WhiteTextTypography variant="h5">
                Project Details
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box
              m={2}
              justifyContent="flex-end"
              alignItems="flex-end"
              display="flex"
            >
              <button
                onClick={this.handleProjectInfoEdit}
                style={{
                  backgroundColor: "green",
                  padding: "0.4rem 1.3rem",
                  fontWeight: "bold",
                  color: "var(--trade-font)",
                  border: "none",
                  borderRadius: "4px",
                }}
              >
                Edit
              </button>
            </Box>
          </Grid>
          {this.reviewHeaderRow([
            "Title",
            "Project Number",
            "Safety Meeting Recipient",
          ])}

          {this.reviewContentRow([
            this.state.title,
            this.state.number,
            this.state.clientEmail,
          ])}

          {this.reviewHeaderRow(["Project Manager", "Address", "Radius"])}

          {this.reviewContentRow([
            this.state.projectManagerName,
            this.state.address,
            this.state.radius,
          ])}

          {this.reviewHeaderRow(["start date", "end date", "Budget"])}

          {this.reviewContentRow([
            this.state.startDate,
            this.state.endDate,
            this.state.budget,
          ])}
        </Grid>
        <Grid container>
          <Box m={2}></Box>
        </Grid>
        <Grid container className={this.classes.reviewContainer}>
          <Grid item md={9}>
            <Box m={2}>
              <WhiteTextTypography variant="h5">
                Tasks and Safety Meetings
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box
              m={2}
              justifyContent="flex-end"
              alignItems="flex-end"
              display="flex"
            >
              <button
                onClick={this.handleTaskEdit}
                style={{
                  backgroundColor: "green",
                  padding: "0.4rem 1.3rem",
                  fontWeight: "bold",
                  color: "var(--trade-font)",
                  border: "none",
                  borderRadius: "4px",
                }}
              >
                Edit
              </button>
            </Box>
          </Grid>
          {this.addedTasksContainer()}
        </Grid>
        <Grid container>
          <Grid item sm={12}>
            <Box
              m={2}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <button
                onClick={
                  this.state.item === null
                    ? this.createProject
                    : this.handleSave
                }
                style={{
                  backgroundColor: "green",
                  padding: "0.7rem 2rem",
                  fontWeight: "bold",
                  color: "var(--trade-font)",
                  border: "none",
                  borderRadius: "4px",
                }}
              >
                {this.state.item === null ? "Create Project" : "Update Project"}
              </button>
              <Snackbar
                open={this.state.snackOpen}
                autoHideDuration={5000}
                onClose={() => this.setState({ snackOpen: false })}
              >
                <Alert
                  onClose={() => this.setState({ snackOpen: false })}
                  severity="error"
                >
                  {this.state.errorMessage}
                </Alert>
              </Snackbar>
            </Box>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  showStep = () => {
    switch (this.state.step) {
      case 0:
        return this.projectDetails();
      case 1:
        return this.tasksAndMeetings();
      case 2:
        return this.reviewPage();
      case 3:
        return <Redirect to="/dashboard" />;
      default:
        return this.projectDetails();
    }
  };

  stepWrapper = () => (
    <div
      style={{
        backgroundColor: "var(--trade-background)",
        minHeight: "100%",
        width: "100%",
        position: "absolute",
        padding: "2rem",
      }}
    >
      {this.progressIndicator()}
      {this.showStep()}
    </div>
  );

  render() {
    return this.stepWrapper();
  }
}

const mapStateToProps = (state) => {
  return {
    current_company: state.companies.current_company,
    user: state.auth.user,
    safety_meeting_infos: state.safety_meetings.items_info,
    workers: state.workers.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // stopTimer: (time_log_id, latitude, longitude) => {
    //     return dispatch(time_logs.stopTimeLog(time_log_id, latitude, longitude));
    // },
    getProjectDetails: (id) => {
      return dispatch(projects.getProjectDetails(id));
    },
    getCompany: (id) => {
      return dispatch(companies.getCompany(id));
    },
    getCompanySafetyMeetingInfos: (company_id) => {
      return dispatch(safety_meetings.getCompanySafetyMeetingInfos(company_id));
    },
    getCompanyWorkers: (company_id) => {
      return dispatch(workers.getCompanyWorkers(company_id));
    },
    updateProject: (state) => {
      let latitude = null;
      let longitude = null;

      if (state.latitude) {
        latitude = state.latitude.toFixed(13);
        longitude = state.longitude.toFixed(13);
      }
      return dispatch(
        projects.updateProject(
          state.item.id,
          state.title,
          state.company,
          state.clientEmail,
          state.autoStartGeofence,
          latitude,
          longitude,
          state.radius,
          state.number,
          state.address,
          state.projectManager,
          state.startDate,
          state.endDate,
          state.budget,
          state.street_address,
          state.city,
          state.province,
          state.country,
          state.postal_code
        )
      );
    },
    createProject: (state) => {
      let latitude = null;
      let longitude = null;

      if (state.latitude) {
        latitude = state.latitude.toFixed(13);
        longitude = state.longitude.toFixed(13);
      }
      return dispatch(
        projects.createProject(
          state.title,
          state.company,
          state.clientEmail,
          state.autoStartGeofence,
          latitude,
          longitude,
          state.radius,
          state.number,
          state.address,
          state.projectManager,
          state.endDate,
          state.budget,
          state.street_address,
          state.city,
          state.province,
          state.country,
          state.postal_code,
          state.startDate
        )
      );
    },
    createSafetyMeetingInfo: (data) => {
      return dispatch(
        safety_meetings.createSafetyMeetingInfo(
          data.company,
          data.title,
          data.code,
          data.description,
          true,
          false,
          data.library,
          data.files,
          data.task_type_id,
          data.clientEmail
        )
      );
    },
    createTask: (company_id, title) => {
      return dispatch(companies.createTask(company_id, title));
    },
    removeTaskFromProject: (taskId, projectId) => {
      return dispatch(projects.removeTaskFromProject(taskId, projectId));
    },
    addTaskToProject: (taskTypeId, projectId) => {
      return dispatch(projects.addTaskToProject(taskTypeId, projectId));
    },
    addSafetyMeetingToTask: (taskTypeId, meetingId) => {
      return dispatch(companies.addSafetyMeetingToTask(taskTypeId, meetingId));
    },
    removeSafetyMeetingFromTask: (taskTypeId, meetingId) => {
      return dispatch(
        companies.removeSafetyMeetingFromTask(taskTypeId, meetingId)
      );
    },
    createFieldForm: (fieldFormData) => {
      return dispatch(safety_meetings.createFieldForm(fieldFormData));
    },
    createWorker: (worker) => {
      return dispatch(
        workers.createWorker(
          worker.firstName,
          worker.lastName,
          worker.email,
          worker.phoneNumber,
          worker.company,
          worker.password,
          worker.isManager,
          worker.role
        )
      );
    },
  };
};

const geolocatedOptions = {
  positionOptions: {
    enableHighAccuracy: true,
  },
  userDecisionTimeout: 10000,
};

AddProjectPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(projectStyles)(geolocated(geolocatedOptions)(AddProjectPage)));
