import { logException, ax } from "../utils";
const endpoint = "/api/safety-meetings-info/";
const smEndpoint = "/api/safety-meetings/";

/// ------------------------------- SAFETY MEETING INFO ---------------------------- /////
export const getSafetyMeeting = (id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(endpoint + id + "/")
      .then((res) => {
        return dispatch({
          type: "GET_SAFETY_MEETING",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

// update item on list of items_info in the store to add new SMI
export const createSafetyMeetingInfo = (
  company,
  title,
  code,
  description,
  daily,
  toolbox,
  library,
  files,
  task_type_id,
  client_email,
  frequency
) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });

    let formData = new FormData();
    formData.append("title", title);
    formData.append("company", company);
    formData.append("code", code);
    formData.append("description", description);
    formData.append("daily", daily);
    formData.append("toolbox", toolbox);
    formData.append("library", library);
    formData.append("client_email", client_email);
    formData.append("frequency", frequency);
    formData.append("is_active", true);

    if (task_type_id !== undefined)
      formData.append("task_type_id", task_type_id);

    files.forEach((fileInfo, index) =>
      formData.append("files_" + index, fileInfo.file, fileInfo.file.name)
    );

    formData.append("files_count", files.length);

    return ax
      .post(endpoint, formData)
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "CREATE_SAFETY_MEETING",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
      });
  };
};

export const createFieldForm = (ffData) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });

    return ax
      .post("/api/field-forms/", {
        order: ffData.order,
        title: ffData.title,
        type: ffData.type,
        safety_meeting_info: ffData.safety_meeting_info,
        options: ffData.options,
        required: false,
      })
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "CREATE_FIELDFORM",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
        logException(err);
      });
  };
};

export const deleteFieldForm = (id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });

    return ax
      .delete("/api/field-forms/" + id + "/")
      .then((res) => {
        return dispatch({
          type: "DELETE_FIELD_FORM",
        });
      })
      .catch((err) => {
        return dispatch({
          type: "DATA_ERROR_DELETE",
          error: err,
        });
      });
  };
};

// update item on list of items_info in the store
export const saveSafetyMeeting = (
  id,
  company,
  title,
  code,
  description,
  daily,
  toolbox,
  library,
  files,
  //images_remove,
  task_type_id,
  client_email,
  frequency
) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    let formData = new FormData();
    formData.append("title", title);
    formData.append("company", company);
    formData.append("code", code);
    formData.append("description", description);
    formData.append("daily", daily);
    formData.append("toolbox", toolbox);
    formData.append("library", library);
    formData.append("client_email", client_email);
    formData.append("frequency", frequency);
    formData.append("is_active", true);

    if (task_type_id !== undefined)
      formData.append("task_type_id", task_type_id);

    files.forEach((fileInfo, index) => {
      if (fileInfo.hasOwnProperty("file"))
        formData.append("files_" + index, fileInfo.file, fileInfo.file.name);
    });
    //formData.append("files_count", files.length);
    //formData.append("images_remove", images_remove);

    return ax
      .put(endpoint + id + "/", formData)
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "UPDATE_SAFETY_MEETING",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
      });
  };
};

// update item on list of items_info in the store
export const activateMeeting = (meetingId) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });

    let formData = new FormData();
    formData.append("is_active", true);
    return ax
      .patch(endpoint + meetingId + "/", formData)
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "UPDATE_SAFETY_MEETING",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
      });
  };
};

// update item on list of items_info in the store
export const archiveMeeting = (meetingId) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    let formData = new FormData();
    formData.append("is_active", false);

    return ax
      .patch(endpoint + meetingId + "/", formData)
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "UPDATE_SAFETY_MEETING",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
      });
  };
};

/// used to fetch SafetyMeetingInfo
// will fetch all SafetyMeetingInfo for the current company
// mostly used when there's an open timelog and current company is implied by the backend
// adds list of safety meeting info to store (items_info)
export const fetchSafetyMeetingInfos = (is_active) => {
  const params = new URLSearchParams();
  if (is_active !== undefined) {
    params.append("is_active", is_active);
  }
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(endpoint + "?" + params)
      .then((res) => {
        return dispatch({
          type: "FETCH_SAFETY_MEETING_INFOS",
          items: res.data.results,
          count: res.data.count,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

// used to fetch SafetyMeetingInfos for a company, but there's not an open timelog
// works the same as the one above
// adds list of safety meeting info to store (items_info)
export const getCompanySafetyMeetingInfos = (company_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(endpoint + "?company=" + company_id)
      .then((res) => {
        return dispatch({
          type: "FETCH_SAFETY_MEETING_INFOS",
          items: res.data.results,
          count: res.data.count,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

// used in site selection, login worker and switch site (doesn't affect store)
export const fetchSafetyMeetingsInfoForTask = (task_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(`/api/tasks-types/${task_id}/safety-meeting-infos/`)
      .then((res) => {
        return dispatch({
          type: "FETCH_TASK_SAFETY_MEETINGS_INFO",
          items: res.data,
          count: res.data.length,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
      });
  };
};

// used in dashboard and tab pane for project slider (project details)
// adds list of safety meetings to store (current_project_items)
export const fetchSafetyMeetingsPerProject = (project_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(smEndpoint + "?project_id=" + project_id)
      .then((res) => {
        return dispatch({
          type: "FETCH_SAFETY_MEETINGS_PROJECT",
          items: res.data.results,
          count: res.data.count,
          next: res.data.next,
          prev: res.data.previous,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
      });
  };
};

export const fetchMoreSafetyMeetingsByProjectId = (url) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(url)
      .then((res) => {
        return dispatch({
          type: "APPEND_PROJECT_SAFETY_MEETINGS",
          items: res.data.results,
          count: res.data.count,
          next: res.data.next,
          prev: res.data.previous,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
      });
  };
};

// used on site selection, login worker and switch site
// doesn't affect store
export const acknowledgeMeeting = (
  meeting_id,
  project_id,
  worker_id,
  fields_values,
  task_id,
  time_log_id
) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .post(smEndpoint, {
        safety_meeting_info_id: meeting_id,
        project_id: project_id,
        fields: fields_values,
        user: worker_id,
        task_id: task_id,
        time_log_id,
      })
      .then((res) => {
        return dispatch({
          type: "ACK_SAFETY_MEETING",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
      });
  };
};

// only used by old components
export const fetchSafetyMeetingsInfoPerProject = (project_id, task_type_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    let url = "/api/projects/" + project_id + "/";
    if (task_type_id) url += "?task_type_id=" + task_type_id;
    return ax
      .get(url)
      .then((res) => {
        return dispatch({
          type: "TEMP_SAFETY_MEETINGS_INFO",
          items: res.data.safety_meetings_info,
          count: res.data.count,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
      });
  };
};

// used by old comonents
export const fetchByWorkerId = (worker_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(smEndpoint + "?worker_id=" + worker_id)
      .then((res) => {
        return dispatch({
          type: "FETCH_SAFETY_MEETINGS_PER_WORKER",
          worker_id: worker_id,
          items: res.data,
          count: res.data.count,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
      });
  };
};
