import React, { useEffect, useState } from "react";
import { ax } from "../../utils";
import { connect } from "react-redux";
import { projects } from "actions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector, shallowEqual } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import SearchDetailSlider from "../searchDetailSlider/SearchDetailSlider";

import "./SearchDashboard.css";

const StyledTextField = withStyles({
  root: {
    "& .MuiAutocomplete-endAdornment": {
      filter: "invert(1)",
    },
  },
})(TextField);

function SearchBox(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(null);
  const [sliderOpen, setSliderOpen] = useState(false);
  const [fetchedProjects, setFetchedProjects] = useState([]);
  const [next, setNext] = useState(null);

  const { company_id, getProjects, user } = props;

  const workers = useSelector((state) => state.workers.items, shallowEqual);
  const projects = useSelector((state) => state.projects.items, shallowEqual);

  // uncomment if adding Safety Meetings to search
  /* 
  const safety_meetings = useSelector(
    (state) => state.safety_meetings.items_info,
    shallowEqual
  ); 
   */

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  useEffect(() => {
    if (company_id) {
      ax.get(
        "/api/projects/short/?limit=100&archived=false&company=" + company_id
      )
        .then((res) => {
          setFetchedProjects(res.data.results);
          setNext(res.data.next);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    const worker_items = workers
      .map((worker) => ({
        title: `${worker.user.first_name} ${worker.user.last_name}`,
        type: "worker",
        item: worker,
      }))
      .sort((a, b) =>
        a.item.user.first_name.localeCompare(b.item.user.first_name)
      );

    const project_items = fetchedProjects.map((project) => ({
      title: project.title,
      type: "project",
      item: project,
    }));

    // const safety_meeting_items = safety_meetings.map((meeting) => ({
    //   title: meeting.title,
    //   type: "meeting",
    //   item: meeting,
    // }));

    if (props.user.role !== "WORKER") {
      setItems([...worker_items, ...project_items]);
    } else {
      setItems([...project_items]);
    }
  }, [workers, fetchedProjects]);

  const styles = {
    avatarStyle: {
      borderRadius: "10px",
      height: "2.5rem",
      width: "2.5rem",
    },
    searchIconStyle: {
      color: "white",
    },

    projectContainerStyle: {
      margin: "30px",
    },
  };

  const searchProjects = (event) => {
    if (event.target.value !== "") {
      ax.get("/api/projects/search?q=" + event.target.value).then((res) => {
        setFetchedProjects(res.data.results);
        setNext(res.data.next);
      });
    }
  };

  const fetchMoreData = async () => {
    ax.get(next)
      .then((res) => {
        setFetchedProjects([...fetchedProjects, ...res.data.results]);
        setNext(res.data.next);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleSearch = () => {};

  const handleChange = (ev, newValue) => {
    if (newValue) {
      setSliderOpen(true);
      setItem(newValue);
    } else {
      setSliderOpen(false);
      setItem(null);
    }
  };

  const GlassButton = () => {
    return (
      <Button onClick={handleClickOpen}>
        <SearchIcon style={styles.searchIconStyle} />
      </Button>
    );
  };

  const SearchField = React.memo(({ items }) => {
    return (
      <Autocomplete
        loading
        options={items}
        groupBy={(option) => option.type}
        getOptionLabel={(option) => option.title}
        onChange={(event, newValue) => handleChange(event, newValue)}
        onInputChange={(event) => searchProjects(event)}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            label={
              props.user.role !== "WORKER" ? "Projects, Workers" : "Projects"
            }
            margin="normal"
            variant="outlined"
            className="search-dropdown"
          />
        )}
      />
    );
  });

  const handleDrawerClose = () => {
    setItem(null);
    setSliderOpen(false);
  };

  return (
    <div>
      <GlassButton />
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Search Dashboard</DialogTitle>
        <DialogContent>
          <div style={{ width: 300 }}>
            <SearchField items={items} />
          </div>
          <div style={{ textAlign: "right" }}>
            <Button onClick={handleSearch} color="primary">
              Search
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <SearchDetailSlider
        drawerOpen={sliderOpen}
        closeDrawer={handleDrawerClose}
        item={item}
        company={company_id}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    projects: state.projects.global_items,
    company_id: state.companies.current_company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProjects: (company_id) => {
      return dispatch(projects.getShortProjects(company_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
