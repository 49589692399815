import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import "./SideDrawerProject.css";
import Spinner from "react-bootstrap/Spinner";
import DatePicker from "react-datepicker";
import DayTableTimeSheet from "../TableSlider/TableSlider";
import "react-datepicker/dist/react-datepicker.css";
import NotesTimeline from "../NotesTimeline/NotesTimeline";
import RenderInWindow from "../RenderInNewWindow/RenderInNewWindow";
import { makeStyles } from "@material-ui/core/styles";
import { ax } from "utils";
import { connect } from "react-redux";

// here
var moment = require("moment"); // require

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "99%",
    height: "300px",
    backgroundColor: "#000000",
    marginTop: 10,
  },
  nextButton: {
    backgroundColor: "#000000",
    padding: "0.7rem",
    color: "white",
    margin: "1rem auto",
    textAlign: "center",
  },
}));

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const types = {
  FILES: "files",
  NOTES: "notes",
  PHOTOS: "photos",
  GPS_ENTRIES: "gps-entries",
  TIMELOGS: "logs",
  SAFETY_MEETINGS: "safety-meetings",
};

function SideDrawerProject(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);

  // objects
  const [totalItems, setTotalItems] = useState([]);
  const [dayItems, setDayItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [next, setNext] = useState(null);
  const [noteImagesNext, setNoteImagesNext] = useState(null);

  const [isExportingLogs, setIsExportingLogs] = useState(false);

  const [openPrint, setOpenPrint] = useState();
  const [timesheetData, setTimesheetData] = useState();

  const [startDate, endDate] = dateRange;
  const { item, type, project_id } = props;

  useEffect(() => {
    organizeData();
  }, [totalItems, dateRange]);

  const fetchData = (month, year) => {
    var endpoint = "";
    if (type === types.FILES) {
      endpoint =
        "/api/project-files/?project_id=" +
        project_id +
        "&file_type=document&limit=10";
      ax.get(endpoint)
        .then((res) => {
          setTotalItems(res.data.results);
          setNext(res.data.next);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else if (type === types.NOTES) {
      endpoint = "/api/notes/?project_id=" + project_id + "&limit=10";
      ax.get(endpoint)
        .then((res) => {
          setTotalItems(res.data.results);
          setNext(res.data.next);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else if (type === types.PHOTOS) {
      fetchImages(month, year).then((res) => {
        setTotalItems(res.items);
        setNext(res.imagesNext);
        setNoteImagesNext(res.noteImagesNext);
        setLoading(false);
      });
    } else if (type === types.GPS_ENTRIES) {
      endpoint =
        "/api/gps-entries/?project_id=" +
        project_id +
        "&month=" +
        month +
        "&year=" +
        year;
      ax.get(endpoint)
        .then((res) => {
          setTotalItems(res.data.results);
          setNext(res.data.next);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else if (type === types.TIMELOGS) {
      endpoint =
        "/api/time-logs/?project_id=" +
        project_id +
        "&month=" +
        month +
        "&year=" +
        year;
      ax.get(endpoint)
        .then((res) => {
          let items = res.data.results.map((log) => {
            log.start = moment(log.start);
            log.end = moment(log.end);
            return log;
          });

          setTotalItems(items);
          setNext(res.data.next);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else if (type === types.SAFETY_MEETINGS) {
      endpoint =
        "/api/safety-meetings/?project_id=" +
        project_id +
        "&month=" +
        month +
        "&year=" +
        year;
      ax.get(endpoint)
        .then((res) => {
          setTotalItems(res.data.results);
          setNext(res.data.next);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchImages = async (month, year) => {
    const imagesEndpoint =
      "/api/project-files/?project_id=" +
      project_id +
      "&file_type=image&limit=10";
    const noteImagesEndpoint =
      "/api/notes-images/?project_id=" + project_id + "&limit=10";

    const imagesResp = await ax.get(imagesEndpoint);
    const noteImagesresp = await ax.get(noteImagesEndpoint);

    const noteImagesTemp = noteImagesresp.data.results.map((elem, index) => ({
      id: elem.id,
      date_created: elem.date_created,
      file: elem.image,
      name: "note image " + index,
      is_copy: false,
    }));

    let items = [...imagesResp.data.results, ...noteImagesTemp];
    items.sort(function (a, b) {
      return b.date_created.localeCompare(a.date_created);
    });

    const response = {
      items: items,
      imagesNext: imagesResp.data.next,
      noteImagesNext: noteImagesresp.data.next,
    };

    return response;
  };

  const fetchMoreData = async () => {
    if (type === types.TIMELOGS) {
      ax.get(next)
        .then((res) => {
          let items = res.data.results.map((log) => {
            log.start = moment(log.start);
            log.end = moment(log.end);
            return log;
          });

          setTotalItems([...totalItems, ...items]);
          setNext(res.data.next);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (type === types.PHOTOS) {
      let imagesResp;
      let noteImagesresp;
      let noteImagestemp = [];
      let imagesTemp = [];
      if (next) {
        imagesResp = await ax.get(next);
        imagesTemp = imagesResp.data.results;

        setNext(imagesResp.data.next);
      }
      if (noteImagesNext) {
        noteImagesresp = await ax.get(noteImagesNext);

        noteImagestemp = noteImagesresp.data.results.map((elem, index) => ({
          id: elem.id,
          date_created: elem.date_created,
          file: elem.image,
          name: "note image " + index,
          is_copy: false,
        }));

        setNoteImagesNext(noteImagesresp.data.next);
      }

      let items = [...imagesTemp, ...noteImagestemp];
      items.sort(function (a, b) {
        return b.date_created.localeCompare(a.date_created);
      });
      setTotalItems([...totalItems, ...items]);
    } else {
      ax.get(next)
        .then((res) => {
          setTotalItems([...totalItems, ...res.data.results]);
          setNext(res.data.next);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const organizeData = () => {
    var itemsInRange = [];
    var tempDayItems = [];
    itemsInRange = sortAccordingToDate(type, totalItems);
    tempDayItems = sortByDay(type, itemsInRange);
    setDayItems(tempDayItems);
  };

  const datePick = () => {
    return (
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        style={{ backgroundColor: "#555", color: "var(--trade-font)" }}
        endDate={endDate}
        onChange={(update) => {
          let formattedStartDate = moment(update[0]).format("YYYY-MM-DD HH:MM");
          let formattedEndDate = moment(update[1]).format("YYYY-MM-DD HH:MM");
          setDateRange(update);
          switch (type) {
            case "logs":
              if (update[1]) {
                ax.get(
                  `/api/time-logs/?project_id=${project_id}&end__lte=${formattedEndDate}&start=${formattedStartDate}`
                ).then((res) => {
                  let items = res.data.results.map((log) => {
                    log.start = moment(log.start);
                    log.end = moment(log.end);
                    return log;
                  });
                  setTotalItems(items);
                });
              }
              break;
            case "gps-entries":
              if (update[1]) {
                ax.get(
                  `/api/gps-entries/?project_id=${project_id}&end__lte=${formattedEndDate}&start=${formattedStartDate}`
                ).then((res) => {
                  let items = res.data.results.map((log) => {
                    log.start = moment(log.start);
                    log.end = moment(log.end);
                    return log;
                  });
                  setTotalItems(items);
                });
              }
              break;
            case "safety-meetings":
              if (update[1]) {
                ax.get(
                  `/api/safety-meetings/?project_id=${project_id}&end__lte=${formattedEndDate}&start=${formattedStartDate}`
                ).then((res) => {
                  let items = res.data.results.map((log) => {
                    log.start = moment(log.start);
                    log.end = moment(log.end);
                    return log;
                  });
                  setTotalItems(items);
                });
              }
              break;
          }
        }}
        isClearable={true}
      />
    );
  };

  const toggleDrawer = (drawerOpen) => () => {
    if (drawerOpen) {
      setOpen(drawerOpen);
      setDateRange([
        moment(item).startOf("month").toDate(),
        moment(item).endOf("month").toDate(),
      ]);

      const month = props.item.split(" ")[0];
      const year = props.item.split(" ")[1];
      const monthIndex = months.indexOf(month);
      const monthParam = monthIndex + 1;
      fetchData(monthParam, year);
    } else {
      setOpen(drawerOpen);
      setTotalItems([]);
      setDayItems([]);
    }
  };

  const sortAccordingToDate = (choice, objects) => {
    var dateAccordingToRange = [];
    var givenStartDate;
    var givenEndDate;
    var sDate, i;

    givenStartDate = moment(startDate);
    givenEndDate = moment(endDate);
    givenEndDate.set({ hour: 23, minute: 59 });

    switch (choice) {
      case types.FILES:
        if (objects.length > 0) {
          const filteredFiles = objects.filter(
            (elem) => elem.file_type === "document"
          );

          for (i = 0; i < filteredFiles.length; i++) {
            sDate = moment(filteredFiles[i].date_created);

            if (sDate.isBetween(givenStartDate, givenEndDate)) {
              dateAccordingToRange.push(filteredFiles[i]);
            }
          }
          return dateAccordingToRange;
        }
        return [];
      case types.NOTES:
        if (objects.length > 0) {
          for (i = 0; i < objects.length; i++) {
            sDate = moment(objects[i].date);
            if (sDate.isBetween(givenStartDate, givenEndDate)) {
              dateAccordingToRange.push(objects[i]);
            }
          }
          return dateAccordingToRange;
        }
        return [];
      case types.PHOTOS:
        if (objects) {
          for (i = 0; i < objects.length; i++) {
            sDate = moment(objects[i].date_created);

            if (sDate.isBetween(givenStartDate, givenEndDate)) {
              dateAccordingToRange.push(objects[i]);
            }
          }

          return dateAccordingToRange;
        }
        return [];
      case types.GPS_ENTRIES:
        if (objects.length > 0) {
          for (i = 0; i < objects.length; i++) {
            sDate = moment(objects[i].date);

            if (sDate.isBetween(givenStartDate, givenEndDate)) {
              dateAccordingToRange.push(objects[i]);
            }
          }

          return dateAccordingToRange;
        }
        return [];
      case types.TIMELOGS:
        if (objects.length > 0) {
          for (i = 0; i < objects.length; i++) {
            sDate = moment(objects[i].start);

            if (sDate.isBetween(givenStartDate, givenEndDate)) {
              dateAccordingToRange.push(objects[i]);
            }
          }

          return dateAccordingToRange;
        }
        return [];
      case types.SAFETY_MEETINGS:
        if (objects.length > 0) {
          givenStartDate = moment(startDate);
          givenEndDate = moment(endDate);
          givenEndDate.set({ hour: 23, minute: 59 });

          for (i = 0; i < objects.length; i++) {
            sDate = moment(objects[i].read_on);

            if (sDate.isBetween(givenStartDate, givenEndDate)) {
              dateAccordingToRange.push(objects[i]);
            }
          }

          return dateAccordingToRange;
        }
        return [];
      default:
        return [];
    }
  };

  const sortByDay = (choice, objectsInRange) => {
    var dict = {};
    var day, value, i;
    var result = [];

    switch (choice) {
      case types.FILES:
        if (objectsInRange.length > 0) {
          for (i = 0; i < objectsInRange.length; i++) {
            day = moment(objectsInRange[i].date_created).format("Do MMMM YYYY");
            if (day in dict) {
              value = dict[day];
              value.push(objectsInRange[i]);
            } else {
              dict[day] = [objectsInRange[i]];
            }
          }

          if (Object.keys(dict).length !== 0) {
            for (const p in dict) {
              const values = dict[p];
              result.push(values);
            }
          }
          return result;
        }
        return [];

      case types.NOTES:
        if (objectsInRange.length > 0) {
          for (i = 0; i < objectsInRange.length; i++) {
            day = moment(objectsInRange[i].date).format("Do MMMM YYYY");

            if (day in dict) {
              value = dict[day];
              value.push(objectsInRange[i]);
            } else {
              dict[day] = [objectsInRange[i]];
            }
          }

          if (Object.keys(dict).length !== 0) {
            for (const p in dict) {
              const values = dict[p];
              result.push(values);
            }
          }

          return result;
        }
        return [];

      case types.PHOTOS:
        if (objectsInRange.length !== 0) {
          for (i = 0; i < objectsInRange.length; i++) {
            day = moment(objectsInRange[i].date_created).format("Do MMMM YYYY");
            if (day in dict) {
              value = dict[day];
              value.push(objectsInRange[i]);
            } else {
              dict[day] = [objectsInRange[i]];
            }
          }

          if (Object.keys(dict).length !== 0) {
            for (const p in dict) {
              const values = dict[p];
              result.push(values);
            }
          }

          return result;
        }
        return [];

      case types.GPS_ENTRIES:
        if (objectsInRange.length !== 0) {
          for (i = 0; i < objectsInRange.length; i++) {
            day = moment(objectsInRange[i].date).format("Do MMMM YYYY");
            if (day in dict) {
              value = dict[day];
              value.push(objectsInRange[i]);
            } else {
              dict[day] = [objectsInRange[i]];
            }
          }

          if (Object.keys(dict).length > 0) {
            for (const p in dict) {
              const values = dict[p];
              result.push(values);
            }
          }

          return result;
        }
        return [];

      case types.TIMELOGS:
        if (objectsInRange.length !== 0) {
          for (i = 0; i < objectsInRange.length; i++) {
            day = moment(objectsInRange[i].start).format("Do MMMM YYYY");
            if (day in dict) {
              value = dict[day];
              value.push(objectsInRange[i]);
            } else {
              dict[day] = [objectsInRange[i]];
            }
          }

          if (Object.keys(dict).length > 0) {
            for (const p in dict) {
              const values = dict[p];
              result.push(values);
            }
          }

          return result;
        }

        return [];

      case types.SAFETY_MEETINGS:
        if (objectsInRange.length !== 0) {
          for (i = 0; i < objectsInRange.length; i++) {
            day = moment(objectsInRange[i].read_on).format("Do MMMM YYYY");
            if (day in dict) {
              value = dict[day];
              value.push(objectsInRange[i]);
            } else {
              dict[day] = [objectsInRange[i]];
            }
          }

          if (Object.keys(dict).length !== 0) {
            for (const p in dict) {
              const values = dict[p];
              result.push(values);
            }
          }

          return result;
        }

        return [];

      default:
        return [];
    }
  };

  const open_file = (file) => {
    window.open(file.file, "_blank");
  };

  const exportFile = () => {
    const year = props.item.split(" ")[1];
    const month = props.item.split(" ")[0];
    const monthIndex = months.indexOf(month);
    const monthParam = monthIndex + 1;
    if (type === types.GPS_ENTRIES) {
      window.open(
        process.env.API_URL +
          "/api/gps-entries/export_csv/?month=" +
          monthParam +
          "&year=" +
          year +
          "&project_id=" +
          project_id +
          "&token=" +
          props.token,
        "_blank"
      );
    } else {
      window.open(
        process.env.API_URL +
          "/api/time-logs/export_csv/?month=" +
          monthParam +
          "&year=" +
          year +
          "&project_id=" +
          project_id +
          "&token=" +
          props.token,
        "_blank"
      );
    }
  };

  const printFile = () => {
    const year = props.item.split(" ")[1];
    const month = props.item.split(" ")[0];
    const monthIndex = months.indexOf(month);
    const monthParam = monthIndex + 1;

    if (type === types.GPS_ENTRIES) {
      ax.get(
        process.env.API_URL +
          "/api/gps-entries/print_gps_entries/?month=" +
          monthParam +
          "&year=" +
          year +
          "&project_id=" +
          project_id
      ).then((res) => {
        setTimesheetData(res.data);
        setOpenPrint(true);
      });
    } else {
      ax.get(
        process.env.API_URL +
          "/api/time-logs/print_time_logs/?month=" +
          monthParam +
          "&year=" +
          year +
          "&project_id=" +
          project_id
      ).then((res) => {
        setTimesheetData(res.data);
        setOpenPrint(true);
      });
    }
  };

  const LoadMoreButton = () => {
    return next ? (
      <Button
        className={classes.nextButton}
        variant="contained"
        color="primary"
        style={{ padding: "0.8rem 1.1rem" }}
        onClick={fetchMoreData}
      >
        Load More
      </Button>
    ) : null;
  };

  const FileList = () => {
    if (type === types.FILES) {
      return (
        <div>
          <Grid container spacing={2} className="file-grid">
            <Grid item xs={10}>
              <Box
                mt={1}
                justifyContent="center"
                display="flex"
                alignContent="center"
              >
                <ListItem
                  style={{ padding: 0, color: "#FFFF", fontWeight: "bold" }}
                >
                  <ListItemText>Name</ListItemText>
                </ListItem>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                mt={1}
                justifyContent="center"
                display="flex"
                alignContent="center"
              >
                <ListItem
                  style={{ padding: 0, color: "#FFFF", fontWeight: "bold" }}
                >
                  <ListItemText>Date Added</ListItemText>
                </ListItem>
              </Box>
            </Grid>
          </Grid>
          {loading ? (
            <div className="spinnerContainer">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : totalItems.length > 0 ? (
            totalItems.map((item, key) => (
              <Grid key={key} container spacing={2} className="file-grid">
                <Grid item xs={10}>
                  <Box
                    mt={1}
                    justifyContent="center"
                    display="flex"
                    alignContent="center"
                  >
                    <ListItem
                      style={{
                        padding: 0,
                        color: "var(--trade-primary)",
                        cursor: "pointer",
                      }}
                      key={key}
                    >
                      <ListItemText key={key} onClick={() => open_file(item)}>
                        {item.name}
                        {item.restricted && (
                          <p className="restricted-notice">
                            <i>Restricted File</i>
                          </p>
                        )}
                      </ListItemText>
                    </ListItem>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box
                    mt={1}
                    justifyContent="center"
                    display="flex"
                    alignContent="center"
                  >
                    <ListItem
                      style={{
                        padding: 0,
                        color: "#ffffff",
                        cursor: "pointer",
                      }}
                    >
                      <ListItemText>
                        {moment(item.date_created).format("MM/DD/YYYY")}
                      </ListItemText>
                    </ListItem>
                  </Box>
                </Grid>
              </Grid>
            ))
          ) : (
            <div className="warningMessage">
              <span>No {type} for the above period.</span>
            </div>
          )}
          {next ? <LoadMoreButton /> : null}
        </div>
      );
    }
  };

  const NotesList = () => {
    if (type === types.NOTES) {
      return (
        <div>
          {loading ? (
            <div className="spinnerContainer">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : totalItems.length > 0 ? (
            <NotesTimeline item={totalItems} type="notes" />
          ) : (
            <div className="warningMessage">
              <span>No Notes for the above period.</span>
            </div>
          )}
          {next ? <LoadMoreButton /> : null}
        </div>
      );
    }
  };

  const PhotosList = () => {
    if (type === types.PHOTOS) {
      return (
        <div>
          {loading ? (
            <div className="spinnerContainer">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : totalItems.length > 0 ? (
            <div className="photo-grid-project">
              {totalItems.map((item, key) => (
                <Grid key={item.id} container spacing={2} className="file-grid">
                  <Grid item xs={10}>
                    <ListItem style={{ padding: 0, color: "#ff6600" }}>
                      <img
                        onClick={() => open_file(item)}
                        src={item.thumbnail ? item.thumbnail : item.file}
                        alt="photo"
                        style={{
                          width: "110px",
                          height: "110px",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                      />
                    </ListItem>
                  </Grid>
                </Grid>
              ))}
            </div>
          ) : (
            <div className="warningMessage">
              <span>No {type} for the above period.</span>
            </div>
          )}
          {next ? <LoadMoreButton /> : null}
        </div>
      );
    }
  };

  const renderComponents = () => {
    switch (type) {
      case types.FILES:
        return <FileList />;
      case types.NOTES:
        return <NotesList />;
      case types.PHOTOS:
        return <PhotosList />;
    }
  };

  const DrawerContent = () => {
    if (type === types.GPS_ENTRIES) {
      return (
        <div
          style={{
            width: "60vw",
            backgroundColor: "#000000",
            textAlign: "center",
          }}
          onKeyDown={toggleDrawer(false)}
        >
          <div className="logs-top-row">
            <div className="log-top-row-buttons">
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: "0.8rem 1.1rem" }}
                onClick={() => exportFile()}
              >
                Export as csv
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: "0.8rem 1.1rem" }}
                onClick={() => printFile()}
              >
                Print
              </Button>
              {openPrint && (
                <RenderInWindow
                  timesheetData={timesheetData}
                  setOpen={setOpenPrint}
                ></RenderInWindow>
              )}
            </div>
            <div className="datePicker"> Pick Date: {datePick()}</div>
          </div>

          <div className="drawer-body">
            <div className="drawer-header">
              <span className="title">
                {moment(startDate)._isValid && moment(endDate)._isValid
                  ? moment(startDate).format("Do MMMM YYYY") +
                    " - " +
                    moment(endDate).format("Do MMMM YYYY")
                  : "Please choose date"}
              </span>
            </div>
          </div>
          {loading ? (
            <div className="spinnerContainer">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : dayItems.length > 0 ? (
            dayItems.map((item, key) => {
              return (
                <div key={key}>
                  <div className="dayValue">
                    <h3>{moment(item[0].date).format("Do MMMM YYYY")}</h3>
                    <DayTableTimeSheet tableData={item} key={key} type={type} />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="warningMessage">
              <span>No GPS entries for the above period.</span>
            </div>
          )}
          <LoadMoreButton />
        </div>
      );
    } else if (type === types.TIMELOGS) {
      return (
        <div
          style={{
            width: "60vw",
            backgroundColor: "#000000",
            textAlign: "center",
          }}
          onKeyDown={toggleDrawer(false, "close")}
        >
          <div className="logs-top-row">
            <div className="log-top-row-buttons">
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: "0.8rem 1.1rem" }}
                onClick={() => exportFile()}
              >
                Export as csv
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: "0.8rem 1.1rem" }}
                onClick={() => printFile()}
              >
                Print
              </Button>
              {openPrint && (
                <RenderInWindow
                  timesheetData={timesheetData}
                  setOpen={setOpenPrint}
                ></RenderInWindow>
              )}
            </div>
            <div className="datePicker"> Pick Date: {datePick()}</div>
          </div>

          <div className="drawer-body">
            <div className="drawer-header">
              <span className="title">
                {moment(startDate)._isValid && moment(endDate)._isValid
                  ? moment(startDate).format("Do MMMM YYYY") +
                    " - " +
                    moment(endDate).format("Do MMMM YYYY")
                  : "Please choose date"}
              </span>
            </div>
          </div>
          {loading ? (
            <div className="spinnerContainer">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : dayItems.length > 0 ? (
            dayItems.map((item, key) => {
              return (
                <div key={key}>
                  <div className="dayValue">
                    <h3>{moment(item[0].start).format("Do MMMM YYYY")}</h3>
                  </div>
                  <DayTableTimeSheet
                    tableData={item}
                    key={key}
                    type={type}
                    item={props.item}
                  />
                </div>
              );
            })
          ) : (
            <div className="warningMessage">
              <span>No Logs for the above period.</span>
            </div>
          )}
          <LoadMoreButton />
        </div>
      );
    } else if (type === types.SAFETY_MEETINGS) {
      return (
        <div
          style={{
            width: "60vw",
            backgroundColor: "#000000",
            textAlign: "center",
          }}
          onKeyDown={toggleDrawer(false, "close")}
        >
          <div className="datePicker"> Pick Date: {datePick()}</div>
          <div className="drawer-body">
            <div className="drawer-header">
              <span className="title">
                {moment(startDate)._isValid && moment(endDate)._isValid
                  ? moment(startDate).format("Do MMMM YYYY") +
                    " - " +
                    moment(endDate).format("Do MMMM YYYY")
                  : "Please choose date "}
              </span>
            </div>
          </div>
          {loading ? (
            <div className="spinnerContainer">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : dayItems.length > 0 ? (
            dayItems.map((item, key) => {
              return (
                <div key={key}>
                  <div className="dayValue">
                    <h3> {moment(item[0].read_on).format("Do MMMM YYYY")}</h3>
                    <DayTableTimeSheet tableData={item} key={key} type={type} />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="warningMessage">
              <span>No Safety Meetings Read for the above period.</span>
            </div>
          )}
          <LoadMoreButton />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      <React.Fragment>
        <List>
          <ListItem
            style={{
              padding: 0,
              color: "var(--trade-primary)",
              cursor: "pointer",
            }}
            onClick={toggleDrawer(true)}
          >
            <ListItemText>{item}</ListItemText>
          </ListItem>
          {renderComponents()}
        </List>
        <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
          <div
            style={{
              backgroundColor: "var(--trade-body)",
              color: "var(--trade-font)",
              minHeight: "100vh",
            }}
          >
            <DrawerContent />
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    project: state.projects.item,
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(SideDrawerProject);
