import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "./SideDrawerWorker.css";
import LastGPSLocation from "../LastGPSLocation/LastGPSLocation";
import Moment from "react-moment";
import WorkerTabPane from "../WorkerTabs/WorkerTabPane";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Gravatar from "react-gravatar";
import AssignWorker from "../AssignWorker/AssignWorker";
import { ax } from "utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

const SpinnerProgress = withStyles({
  root: {
    //background: "var(--trade-success)",
    color: "var(--trade-primary)",
    width: "70px",
    height: "70px",
  },
  colorPrimary: {
    color: "var(--trade-primary)",
  },
})(CircularProgress);

function CircularIndeterminate() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={"100vh"}
    >
      <SpinnerProgress color="primary" />
    </Box>
  );
}

const useStyles = makeStyles({
  list: {
    width: 1050,
    backgroundColor: "var(--trade-body)",
    //minHeight: "100vh",
    color: "var(--trade-font)",
  },
  mainContainer: {
    backgroundColor: "var(--trade-body)",
    minHeight: "100vh",
    color: "var(--trade-font)",
  },
});

function SideDrawerContent(props) {
  const classes = useStyles();
  const { item } = props;
  const [worker, setWorker] = useState(null);
  const [projects, setProjects] = useState([]);

  const styles = {
    avatarStyle: {
      borderRadius: "10px",
      height: "3.125rem",
      width: "3.125rem",
    },
    autocompleteStyle: { width: "350px", color: "white" },
  };

  useEffect(() => {
    if (item.item) {
      getWorkerDetails(item.item);
    }
  }, [item]);

  const getWorkerDetails = (item) => {
    setWorker(item);
    // worker projects
    ax.get("/api/users/" + item.id + "/projects")
      .then((res) => {
        setProjects(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setWorker(null);
    setProjects([]);
  };

  const ProjectList = () => {
    return (
      <div>
        <h6 className="assigned-worker-title">Assigned Jobs</h6>
        {projects.length > 0 ? (
          <div className="worker-assigned-table">
            <List>
              {projects.map((item, key) => (
                <ListItem key={key}>
                  <ListItemText key={key}>
                    <div className="worker-assigned-list-item">
                      <div className="company-avatar-container">
                        {" "}
                        {item.company_logo ? (
                          <Avatar
                            variant="square"
                            style={styles.avatarStyle}
                            src={item.company_logo}
                          />
                        ) : (
                          <Gravatar
                            email={item.client_email}
                            size={40}
                            style={{ borderRadius: "10%" }}
                          />
                        )}
                      </div>
                      &nbsp; &nbsp; &nbsp;
                      <div className="project-assigned">
                        <span>{item.title}</span>
                        <span>{item.address}</span>
                      </div>
                    </div>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </div>
        ) : (
          <span className="assigned-worker-title">
            Worker has not been assigned to any project.
          </span>
        )}
      </div>
    );
  };

  const DrawerContent = () => {
    return worker ? (
      <div
        className={clsx(classes.list)}
        role="presentation"
        onKeyDown={() => handleClose()}
      >
        <div className="main-section">
          <div className="slider-title">
            {worker !== undefined && worker.last_time_log !== undefined ? (
              worker.last_time_log !== null ? (
                worker.last_time_log.end ? (
                  worker.last_time_log.end !== null ? (
                    <span className="dot-red"></span>
                  ) : (
                    <span className="dot-green"></span>
                  )
                ) : (
                  <span className="dot-green"></span>
                )
              ) : (
                <span className="dot-red"></span>
              )
            ) : null}
            &nbsp;
            {worker
              ? worker.user.first_name + " " + worker.user.last_name
              : null}
          </div>

          {worker.role !== "WORKER" ? (
            <div className="worker-slider-buttons-container">
              <AssignWorker
                type="worker"
                worker_id={worker.user.id}
                worker_name={worker.user.first_name}
              />
            </div>
          ) : null}
        </div>

        <div className="worker-contact-details-container">
          <div className="worker-contact-details">
            <span className="txt">Contact</span>

            <div className="worker-contact-details-icons-container">
              <div className="tooltip-con">
                <MailIcon />
                <span className="tooltiptext">{worker.email}</span>
              </div>
              <div className="tooltip-con">
                <PhoneIphoneIcon />
                <span className="tooltiptext">{worker.phone_number}</span>
              </div>
            </div>
          </div>
          <div className="worker-contact-details">
            <span className="txt">Status</span>

            {worker.last_time_log ? (
              worker.last_time_log.end ? (
                worker.last_time_log.end !== null ? (
                  <span>Off-Site</span>
                ) : (
                  <span>On-Site</span>
                )
              ) : (
                <span>On-Site</span>
              )
            ) : (
              <span>Off-Site</span>
            )}
          </div>
        </div>

        {worker.last_time_log ? (
          worker.last_time_log.end !== null ? (
            <div className="worker-offsite-text-container">
              <span className="worker-offsite-text">Worker not on-Site</span>
            </div>
          ) : (
            <div>
              <div className="map-container-worker">
                {" "}
                <div className="worker-location-map">
                  <LastGPSLocation
                    latitude={worker.last_time_log.latitude}
                    longitude={worker.last_time_log.longitude}
                    worker={worker}
                  />
                </div>
              </div>
              <div className="worker-project-info-container">
                <div className="worker-project-info">
                  <div className="project-details">
                    <strong>{worker.last_time_log.project.title}</strong>
                    <span>{worker.last_time_log.project.address}</span>
                  </div>
                  &nbsp;
                  <div className="worker-start-time">
                    <span>Start Time</span>
                    <Moment format={"LT"}>{worker.last_time_log.start}</Moment>
                  </div>
                  &nbsp;
                  <div className="worker-start-time">
                    <span>Duration(HH:MM:SS)</span>
                    <Moment date={worker.last_time_log.start} durationFromNow />
                  </div>
                  &nbsp;
                  <div className="worker-end-time">
                    <span>End Time</span>
                    {/* <Moment format={"LT"}>{worker.last_time_log.start}</Moment> */}
                    -
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="worker-offsite-text-container">
            <span className="worker-offsite-text">New Worker</span>
          </div>
        )}

        {worker !== undefined && worker.last_time_log !== undefined ? (
          <div className="worker-tab-pane">
            <WorkerTabPane worker_id={worker.id} />
          </div>
        ) : null}
        <ProjectList />
      </div>
    ) : (
      <div style={{ width: "1050px", margin: "auto", textAlign: "center" }}>
        <CircularIndeterminate className={classes.spinner} />
      </div>
    );
  };

  return (
    <div className={classes.mainContainer}>
      <DrawerContent />
    </div>
  );
}

export default SideDrawerContent;
