import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { geolocated } from "react-geolocated";
import OnSite from "components/Dashboard/OnSite";
import withStyles from "@material-ui/core/styles/withStyles";
import OffSite from "components/Dashboard/OffSite";
import BaseComponent from "views/BaseComponent";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { workers, projects } from "actions";
import "./Dashboard.css";
import ProjectInfoBoard from "../../newComponents/ProjectInfoBoard/ProjectInfoBoard";
import Spinner from "react-bootstrap/Spinner";
class Dashboard extends BaseComponent {
  state = {
    coords: { longitude: null, latitude: null },
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount() {
    // this.props.getProjects();
    this.askNotificationsPermissions();
    //this.props.getWorkers();
    this.initState(this.state);
    super.componentDidMount();
  }

  static urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    let outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  askNotificationsPermissions() {
    // Only ask permission if it supports it and it's PWA (standalone)
    if (
      "PushManager" in window &&
      window.registration !== undefined &&
      window.matchMedia("(display-mode: standalone)").matches
    ) {
      window.registration.pushManager.getSubscription().then((sub) => {
        const isSubscribed = !(sub === null);
        if (!isSubscribed) {
          const publicKey = Dashboard.urlBase64ToUint8Array(
            "BJOCLxfXeqX1JgK8m6Qi6RruLUQrIVsgFFQprEuXqxR8IulQfRXXahHDwRhW09wJdos2TvS6zB1g9gtaAu-kEMI"
          );
          window.registration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: publicKey,
            })
            .then((res) => {
              this.props.savePubSub(res.toJSON()).then(() => {
                console.log("sub complete");
              });
            });
        } else {
          //console.log(sub.toJSON());
        }
      });
    }
  }

  static differentCoords(a, b) {
    return a.latitude !== b.latitude && a.longitude !== b.longitude;
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps.coords &&
      Dashboard.differentCoords(this.state.coords, nextProps.coords)
    ) {
      this.setState({ coords: nextProps.coords });
      this.locationChanged(nextProps.coords);
    }
    if (
      this.props.isGeolocationEnabled &&
      nextProps.isGeolocationEnabled === false
    ) {
      this.props.gpsRefused();
    } else if (
      this.props.isGeolocationEnabled === false &&
      nextProps.isGeolocationEnabled === true
    ) {
      this.props.gpsAccepted();
    }
  }

  locationChanged(coords) {
    this.props.updatePosition(coords.latitude, coords.longitude);
  }

  redirect = () => {
    window.location.reload();
  };

  render() {
    const { user, projects } = this.props;
    const { latitude, longitude } = this.state.coords;
    const last_time_log = user.last_time_log || null;

    var styles = {
      pTagStyle: {
        color: "white",
        fontSize: "3rem",
        textTransform: "uppercase",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    };

    return (
      <div className="grid-container">
        {/* {user.is_manager ? ( */}
        <ProjectInfoBoard
          redirect={this.redirect}
          latitude={latitude}
          longitude={longitude}
        />
        {/* ) : last_time_log === null || last_time_log.end !== null ? (
          <OnSite
            user={user}
            projects={projects}
            latitude={latitude}
            longitude={longitude}
            gpsEnabled={this.props.isGeolocationEnabled}
            history={this.props.history}
          />
        ) : (
          <OffSite
            user={user}
            projects={projects}
            latitude={latitude}
            longitude={longitude}
            forceGPS={user.gps_forced}
          />
        )} */}
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    projects: state.projects.items,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getProjects: () => {
    //   dispatch(projects.fetchProjects(1));
    // },
    savePubSub: (sub) => {
      return dispatch(workers.savePushSub(sub));
    },
    updatePosition: (latitude, longitude) => {
      return dispatch(workers.updatePosition(latitude, longitude));
    },
    getWorkers: () => {
      return dispatch(workers.fetchWorkers());
    },
    gpsRefused: () => {
      return dispatch(workers.gpsRefused());
    },
    gpsAccepted: () => {
      return dispatch(workers.gpsAccepted());
    },
  };
};

const geolocatedOptions = {
  positionOptions: {
    enableHighAccuracy: true,
  },
  userDecisionTimeout: 10000,
  watchPosition: true,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(geolocated(geolocatedOptions)(Dashboard)));
